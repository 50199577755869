import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {
  getData,
  postData,
  putData,
  deleteData,
  getDownloadDataExcel,
} from "./api";
import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { formatDate } from "./apis/common/Common";
import { UserGet } from "./apis/myAccount/myAccount";
export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:1999";
  const IMG_URL = "https://flicknshoot-node.profcymabackend.com:1998";
  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");

  const [userdata, setUserData] = useState({});

  const [appSetup, setAppSetup] = useState({});
  const [isAllow, setisAllow] = useState([]);

  const [shimmerLoader, setShimmerLoader] = useState(false);
  const minLength = 2;
  const maxLength = 30;

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const isTokenValid = async () => {
    const token = Cookies.get("flick_N_shoot_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("flick_N_shoot_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        // getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);

        await setSignin(true);
        getUser();
      }
    } else {
      setisAllow([]);
      await setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        setAppSetup,
        appSetup,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        isTokenValid,
        formatDate,
        getUser,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
