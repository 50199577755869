import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Studio-inner.css";
import Breadcrumb_comp from "./Breadcrumb-comp/Breadcrumb_comp";
import Studio_card_one from "./Studio-card/Studio_card_one";
import Studio_slider from "./studio-slider/Studio_slider";
import Tabs_section from "../Studio_header/tabs-section/Tabs_section";
import { Context } from "../../utils/context";
import { mediaSpaceDetails } from "../../utils/apis/MediaSpace/MediaSpace";

const Studio_inner = () => {
  const [data, setData] = useState();
  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const navigate = useNavigate(); // Hook for navigation

  const getAllData = async () => {
    const res = await mediaSpaceDetails(id);
    if (res?.success) {
      setData(res?.data);
    } else {
      alert("Studio is inactive");
      navigate("/");
    }
  };

  useEffect(() => {
    getAllData();
  }, [id]);

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="top-stud-bg"></div>
      <Breadcrumb_comp data={data} />
      <Studio_card_one data={data} />
      <Studio_slider data={data} />
      <Tabs_section data={data} />
    </>
  );
};

export default Studio_inner;
