import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import "./MonthlyCard.css";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { postSubscriber } from "../../../utils/apis/login/Login";
import Success_modal from "../../common-section/Modals/Success_modal/Success_modal";
import { Context } from "../../../utils/context";

const MonthlyCard = ({
  image,
  content,
  RupeesText,
  type,
  subscription_id,
  description,
  allData,
  premium_studios,
  subscription_status,
  activeplan,
}) => {
  const { handleSubmit, setError, reset } = useForm();
  const [razorid, setRazorId] = useState();
  const [Razorpay] = useRazorpay();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();


  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    userdata,
  } = useContext(Context);

  const handleRazorpay = async () => {
    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(RupeesText) * 100,
      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (payment_id) => {
    setLoading(true);

    const datafinal = {
      transaction_id: payment_id,
      price: RupeesText,
      subscription_id: subscription_id,
      type: type,

      premium_studio_count:
        allData?.premium_studios === 0
          ? 0
          : type === "Month"
          ? description?.access_premium_studio_no
          : type === "6 Month"
          ? description?.access_premium_studio_no * 6
          : description?.access_premium_studio_no * 12,

      rechedule_booking_count:
        allData?.reschedule === 0
          ? 0
          : type === "Month"
          ? description?.rechedule_month
          : type === "6 Month"
          ? description?.rechedule_month * 6
          : description?.rechedule_month * 12,
    };

    if (type === "Month") {
      const currentDate = new Date(); // Get today's date
      const expiryDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + 1)
      );
      datafinal.expiry_date = expiryDate.toISOString().split("T")[0]; // Format the date as 'YYYY-MM-DD'
    }

    if (type === "6 Month") {
      const currentDate = new Date(); // Get today's date
      const expiryDate = new Date(
        currentDate.setMonth(currentDate.getMonth() + 6)
      );
      datafinal.expiry_date = expiryDate.toISOString().split("T")[0]; // Format the date as 'YYYY-MM-DD'
    }

    if (type === "Year") {
      const currentDate = new Date(); // Get today's date
      const expiryDate = new Date(
        currentDate.setFullYear(currentDate.getFullYear() + 1)
      ); // Add 1 year
      datafinal.expiry_date = expiryDate.toISOString().split("T")[0]; // Format the date as 'YYYY-MM-DD'
    }

    const res = await postSubscriber(datafinal);

    if (res?.success) {
      reset();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/");
      }, 3000);
    } else {
      setError("message", res?.message);
    }

    setLoading(false);
  };

  return (
    <div className="montly-card">
      <Form>
        <div className="form-start">
          <Form.Group className="mb-3" id="formGridCheckbox1">
            {allData?.base_price_discount == 1 && (
              <Form.Check
                disabled
                type="checkbox"
                label={`${description.base_price_percent}% off on base price on every ${description.base_price_booking_no}th booking `}
                defaultChecked
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" id="formGridCheckbox2">
            {allData?.free_booking_in_hours == 1 && (
              <Form.Check
                disabled
                type="checkbox"
                label={`${description.free_booking_hours}hr free booking on every ${description.free_booking_hours_booking_no}th booking on selected studios`}
                defaultChecked
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" id="formGridCheckbox3">
            {allData?.reschedule == 1 && (
              <Form.Check
                disabled
                type="checkbox"
                label={`re-schedule booking ${description?.rechedule_month} Times a Month`}
                defaultChecked
              />
            )}
          </Form.Group>
          <Form.Group className="mb-3" id="formGridCheckbox4">
            {allData?.premium_studios == 1 && (
              <Form.Check
                disabled
                type="checkbox"
                label={`access to upto ${
                  description?.access_premium_studio_no == 0
                    ? "All"
                    : description?.access_premium_studio_no
                } premium studios`}
                defaultChecked
              />
            )}
          </Form.Group>
          {allData?.customer_support == 1 &&
          description?.customer_support_instant == 1 ? (
            <Form.Group className="mb-3" id="formGridCheckbox6">
              <Form.Check
                disabled
                type="checkbox"
                label="instant customer support"
                defaultChecked
              />
            </Form.Group>
          ) : (
            ""
          )}
          {allData?.customer_support == 1 &&
          description?.customer_support_priority == 1 ? (
            <Form.Group className="mb-3" id="formGridCheckbox6">
              <Form.Check
                disabled
                type="checkbox"
                label="Priority customer support"
                defaultChecked
              />
            </Form.Group>
          ) : (
            ""
          )}{" "}
          {allData?.discount_on_platform_fee == 1 ? (
            <Form.Group className="mb-3" id="formGridCheckbox6">
              <Form.Check
                disabled
                type="checkbox"
                label={`${description?.platform_fee_discount}% Platform fee Discount`}
                defaultChecked
              />
            </Form.Group>
          ) : (
            ""
          )}
        </div>
        <div className="image-plan">
          <img className="plan-img" src={image} alt="plan" />
          <div className="overlay-color"></div>
          <div className="text-plan">
            <h2>{content}</h2>
          </div>
        </div>
        <div className="prize-text text-center py-3">
          <h2>₹{RupeesText}</h2>
          <h3>Per {type}</h3>
          {/* {subscription_status ? (
            ""
          ) : (
            <button type="submit" className="select-plan">
              Select Plan
            </button>
          )} */}

          {activeplan ? (
            <>
              {/* <span style={{ color: "green", fontWeight: "bold" }}>
                {activeplan}
              </span> */}

              <button
                type="button"
                style={{
                  color: "green",
                  fontWeight: "bold",
                  background: "#ccc",
                }}
                className="select-plan"
                // disabled={subscription_status}
              >
                {activeplan}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() => {
                if (signin) {
                  handleRazorpay();
                } else {
                  navigate("/login");
                }
              }}
              className="select-plan"
              // disabled={subscription_status}
            >
              Select Plan
            </button>
          )}

          {/* <p className="activeplantext">{activeplan}</p> */}
        </div>
      </Form>

      {/* Modal for success */}
      <Success_modal
        maintext={"Subscription Purchase Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default MonthlyCard;
