import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Success_modal.css";
import SuccessLottey from "./SuccessLottey/SuccessLottey";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
function Success_modal({ smalltext, maintext, ...props }) {
  const { getUser } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.show) {
      setShowModal(true);
      const timer = setTimeout(() => {
        setShowModal(false);
        props.onHide();
        getUser();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 0);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [props.show, props.onHide]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter "
        centered
        show={showModal} // Control visibility with local state
        onHide={() => setShowModal(false)} // Close modal on click outside
        className="Success_modal"
      >
        <Modal.Body>
          <div className="Success_modal_holder ">
            <div className="lottie-holder">
              <SuccessLottey />
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <div className="text-holder text-center">
                  <p className="main-text">{maintext}</p>
                  <p className="small-text">{smalltext}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Success_modal;
