import React, { useEffect, useRef, useState, useContext } from "react";
import MediaBanner from "./MediaBanner/MediaBanner";
import MediaCardSec from "./MediaCardSec/MediaCardSec";
import Home_inquiry from "../home/inquiry/Home_inquiry";
import { Context } from "../../utils/context";
import { mediaSpace } from "../../utils/apis/MediaSpace/MediaSpace";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  getLatLong,
  getLatLongDistance,
} from "../../utils/apis/myAccount/myAccount";
const MediaSpaces = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [data, setData] = useState();


  const [location, setLocation] = useState("");
  const distanceRange = location.distanceRange?.max;

  const [price, setPrice] = useState("");
  const [rating, setRating] = useState("");
  const [pincodes, setPincodes] = useState([]);

  useEffect(() => {
    if (data) {
      const extractedPincodes = data?.data?.map((value) => value?.pincode);
      setPincodes(extractedPincodes); // Set pincodes when data.pincode is available
    }
  }, [data]);

  const [onPageChange, setonPageChange] = useState(1);
  const { id } = useParams();
  const { IMG_URL, postData } = useContext(Context);
  const [totalPages, settotalPages] = useState();
  const getAllData = async () => {
    const res = await mediaSpace(
      onPageChange,
      id,
      "",
      location,
      price,
      rating,
      latitude,
      longitude,
      distanceRange
    );
    if (res?.success) {
      setData(res?.data);
      settotalPages(res?.data?.totalPages);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, [id, onPageChange, location, price, rating]);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  // const [longitude, setLivePincode] = useState();


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch country name using reverse geocoding
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              setLatitude(data?.lat);
              setLongitude(data?.lon);
            })
            .catch((error) => {
              console.error("Error fetching latitude and Longitude:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    // getCurrency();
  }, []);

  return (
    <>
      {/* <MediaBanner data={data[0].category} /> */}
      {/* {data.length > 0 && data?.[0].category ? (
        <MediaBanner data={data?.data?.[0].category} />
      ) : (
        <p>No data available</p>
      )} */}

      <MediaBanner
        data={
          data?.data?.[0]?.category
            ? data?.data?.[0]?.category
            : data?.data?.category
        }
      />

      <MediaCardSec
        data={data}
        id={id}
        location={location}
        setLocation={setLocation}
        price={price}
        setPrice={setPrice}
        rating={rating}
        setRating={setRating}
        setonPageChange={setonPageChange}
        onPageChange={onPageChange}
        settotalPages={settotalPages}
        totalPages={totalPages}
        // liveLocation={liveLocation}
        pincodes={pincodes}
        latitude={latitude}
        longitude={longitude}
      />
      <Home_inquiry id={id} />
    </>
  );
};

export default MediaSpaces;
