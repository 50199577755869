import React, { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import "./RescheduleModal.css";
import {
  recheduleDate,
  recheduleDateSuccessfully,
} from "../../../utils/apis/myAccount/myAccount";
import { Controller, useForm } from "react-hook-form";
import useRazorpay from "react-razorpay";
import Reshedule_Wallet_Payment_modal from "../../common-section/Modals/Reshedule_Wallet_Payment_modal/Reshedule_Wallet_Payment_modal";
import { ColorRing } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import { TimePicker } from "rsuite";
import "rsuite/dist/rsuite.css";

import "react-time-picker/dist/TimePicker.css";
import classNames from "classnames";
import { postCalculation } from "../../../utils/apis/booking/booking";
import { userSubscriptionDetails } from "../../../utils/apis/MediaSpace/MediaSpace";
import { Context } from "../../../utils/context";
const RescheduleModal = (props) => {
  const [Razorpay, openRazorpay] = useRazorpay();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState("");

   const { IMG_URL, appSetup, userdata, setUserData, getUser } = useContext(
      Context
    );
  const data = props?.currentBook?.studio;
  const Booking = props?.currentBook;
  
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      check: false,
      start_date: "",
      rechedule_booking_count: 0,
    },
  });

  const watchStartDate = watch("start_date");

  const watchEndDate = data?.open_24_hours ? watchStartDate : watch("end_date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");

  const startTime = watch("start_time");
  const date = new Date(startTime);
  const [startDate, setStartDate] = useState();
  const [isCalculationDone, setIsCalculationDone] = useState(false);
  const [calculationError, setCalculationError] = useState(false);
  const [buttonOfPayment, setButtonOfPayment] = useState(false);
  const [chargesError, setChargesError] = useState(false);
  const [totalCharge, setTotalCharge] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [useRoundedHours, setRoundedHours] = useState();
  const [studioId, seStudioId] = useState();
  const [useDays, setUseDays] = useState();
  const [remaingAmount, setRemainAmount] = useState(0);


  const [userSubscription, setSubscription] = useState();
  const getUserSubscription = async () => {
    const res = await userSubscriptionDetails();
    if (res?.success) {
      setSubscription(res.data);
    }
  };

  useEffect(() => {
    getUserSubscription();
  }, []);
  const [details, setDetails] = useState({
    days: 0,
    base_price: 0,
    charges: {
      full_h_p: 0,
      full_h_c: 0,
      half_h_p: 0,
      half_h_c: 0,
      min_h_p: 0,
      min_h_c: 0,
    },
    platform_charge: 0,
    reaming_price: 0,
    gst: 0,
    start_time: "00:00:00",
    end_time: "00:00:00",
    hours: 0,
    total: 0,
    add_one_price: 0,
    free_h_p_discount: 0,
  });
  const check = watch("check");
  const rechedule_booking_count = watch("rechedule_booking_count");

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("start_date", data?.start_date);

    try {
      const res = await recheduleDate(props?.show, data);

      if (res?.success) {
        console.log(res?.data);
        setMessage(res?.data?.message);
        reset(res?.data);
      } else {
        console.log(res?.message);

        // Handle setting error for start_date if needed
        setError("start_date", {
          type: "manual",
          message: res?.message || "An error occurred",
        });
      }
    } catch (error) {
      console.error("Error rescheduling date:", error);
      setError("start_date", {
        type: "manual",
        message: "An unexpected error occurred",
      });
    }
  };

  const errorStyle = {
    color: "red",
    fontSize: "14px",
    margin: "10px 0",
  };

  const [modalWalletShow, setModalWalleteShow] = useState();
  const [paymentType, setPaymentType] = useState("wallet");


  const onSelecteSubmit = (data) => {
    console.log(data,"data onSelecteSubmit");
    if (isCalculationDone) {
      if (paymentType === "wallet") {
        finalFunction(data, paymentType);
      } else {
        handleRazorpay(data,details?.reaming_price);
      }
    } else {
      setError("start_date", {
        type: "manual",
        message: "Please press Check",
      });
    }
  };

  useEffect(() => {
    setMessage("");
    reset({
      check: false,
      start_date: "",
    });
  }, [props.show]);

  const finalFunction = async (data, payment_id = null) => {
    try {
      function incrementDate(date, hours = 0) {
        const dateObj = new Date(date);
        dateObj.setHours(dateObj.getHours() + hours);
  
        const options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        };
  
        return dateObj.toLocaleString("en-IN", options);
      }  
      const datafinal = {
        id: Booking?.id,  
        start_time: formattedStartTime,
        capacity: data?.capacity,
        hours: data?.hours,
        end_time: formattedEndTime,
        start_date: incrementDate(data?.start_date),
        end_date: incrementDate(data?.end_date),
        amount: Math.round(details?.total),
        payment_id: payment_id || "wallet",
        days: data?.days,
        p_charge_p: details?.p_charge_p,
        gst_charges: details?.gstchargestoshow,
        afteroffer: details?.afteroffer,
        free_hours: details?.free_h_p_discount,
        base_price_discount_rupees: details?.basepricediscountrupees,
        base_price_discount: details?.base_price_discount,
        base_price: details?.base_price,
        payment_type: paymentType,
        afteroffer: details?.afteroffer,
        payment_type: paymentType,
        reaming_price: details?.reaming_price,
        give_price: details?.give_price,
        reshedule_charges:details?.reshedule_charges,
      
      };
      
      console.log("datafinal data :", datafinal);
      setLoading(true);
      const res = await recheduleDateSuccessfully(datafinal);

      if (res?.success) {
        setModalWalleteShow(false);
        props.onHide();
        props.getAllBookingData();
        props.getUserSubscription();
        props.setModalShow2(true);
        reset();
      } else {
        setError({
          type: "start_date",
          message: res.message,
        });
      }
      setLoading(false);

      console.log(res);
    } catch (error) {
      setError("start_date", {
        type: "manual",
        message: "An unexpected error occurred",
      });
    }
  };

  const handleRazorpay = async (data,remaingAmount) => {
    let amountToPay = Math.round(remaingAmount);

    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(amountToPay * 100),
      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
      handler: async (res) => {
        finalFunction(data,res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const convertToAmPm = (time) => {
    if (!time) return "";
    const [hours, minutes, seconds] = time.split(":");
    let hoursNum = parseInt(hours, 10);
    const amPm = hoursNum >= 12 ? "PM" : "AM";
    hoursNum = hoursNum % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${hoursNum}:${minutes} ${amPm}`;
  };

  const formattedStartTime = date.toLocaleString("en-IN", {
    hour: "2-digit", // Hour in 24-hour format (e.g., 20)
    minute: "2-digit", // Minute (e.g., 00)
    second: "2-digit", // Second (e.g., 00)
    hour12: false, // Ensures 24-hour format is used
  });
  const endTime = new Date(watchEndTime);
  const formattedEndTime = endTime.toLocaleTimeString("en-IN", {
    hour: "2-digit", // Hour in 24-hour format (e.g., 20)
    minute: "2-digit", // Minute (e.g., 00)
    second: "2-digit", // Second (e.g., 00)
    hour12: false,
  });

  useEffect(() => {
    seStudioId(data?.id);
    if (watchStartDate && watchEndDate && watchStartTime && watchEndTime) {
      const startDate = new Date(watchStartDate);

      const endDate = new Date(watchEndDate);

      const startTime = new Date(`1970-01-01T${watchStartTime}:00`);
      const formattedStartTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const endTime = new Date(`1970-01-01T${watchEndTime}:00`);
      const formattedEndTime = endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24) + 1);
      setUseDays(days);

      setValue("days", days);
      function parseTimeToMilliseconds(timeStr) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return (hours * 60 + minutes) * 60 * 1000; // Convert to milliseconds
      }

      const formattedStartTimenow = formattedStartTime;
      const formattedEndTimenow = formattedEndTime;

      const startTimeMs = parseTimeToMilliseconds(formattedStartTimenow);
      const endTimeMs = parseTimeToMilliseconds(formattedEndTimenow);

      const hours = Math.max((endTimeMs - startTimeMs) / (1000 * 60 * 60), 0);
      const roundedHours = Math.ceil(hours);
      setRoundedHours(roundedHours);

      // Calculate charges
      // const dayCharges = days * data?.full_day_charges;
      // const hourCharges = TimeDifference * data?.hourly_basis_charges;

      // setTotalCharge(dayCharges + hourCharges);
    }
  }, [watchStartDate, watchEndDate, watchStartTime, watchEndTime]);

  useEffect(() => {
    if (watchStartDate) {
      setStartDate(new Date(watchStartDate));
    }
  }, [watchStartDate]);

  const onSubmitCalculation = async () => {
    // setIsButtonDisabled(true);
    const startDate = getValues("start_date");
    const formattedStartDate = startDate
      ? new Date(startDate).toLocaleDateString("en-CA") // Formats as YYYY-MM-DD
      : null;

    const endDate = getValues("end_date");
    const formattedEndDate = endDate
      ? new Date(endDate).toLocaleDateString("en-CA") // Formats as YYYY-MM-DD
      : null;

    const f_data = {
      days: data?.open_24_hours == 1 ? 1 : getValues("days"),
      hours: getValues("hours"),
      studio_id: data?.id,
      r_s_time: formattedStartTime,
      r_e_time: formattedEndTime,
      add_one_price: totalCharge,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      reshedule: 1,
      booking_id: Booking?.id,
      // days: days,
      capacity: getValues("capacity"),
    };

    try {
      const res = await postCalculation(f_data);

      if (res?.success) {
        setButtonOfPayment(true);
        setCalculationError(false);
        setDetails(res.data);
        setValue("end_time", getValues("end_time"));
        setValue("calculation_status", res?.success);
        setIsCalculationDone(true);
      

      //  console.log(res?.data?.total,"res?.data?.total");
      //  console.log(Booking?.afteroffer,'Booking?.afteroffer');
       

      //   if (Number(res?.data?.total) <= Number(Booking?.afteroffer)) { 
      //     console.log("less than value");
            
      //     const remaing_amount =
      //       Number(Booking?.afteroffer)- Number(res?.data?.total);
      //       console.log(remaing_amount,'remaing_amount remaing_amount');
            
      //     await setRemainAmount(remaing_amount);
      //   } else {
      //     console.log("greater than value");
      //     const remaing_amount =
      //       Number(res?.data?.total)-Number(Booking?.afteroffer);
      //     await setRemainAmount(remaing_amount);
      //   }
       
      } else {
        setCalculationError(res?.message);
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsButtonDisabled(false);
    }
  };

 
  console.log(userdata?.amount,"userdata?.amount userdata?.amount");
  

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="reschedule-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="rescchedule-title">Reschedule</h4>
            {message && (
              <>
                <p style={{ color: "red", margin: "0", fontSize: "12px" }}>
                  Note: {message}
                </p>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Reschedule Date</Form.Label>

              <Form.Control
                type="date"
                min={new Date().toISOString().split("T")[0]} // Set min date as today
                {...register("start_date", {
                  required: "Reschedule date is required",
                  validate: (value) => {
                    const selectedDate = new Date(value);
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return selectedDate >= today || "Please Select Future Date";
                  },
                })}
                onChange={(e) => {
                  setValue("start_date", e.target.value);
                  setValue("check", false);
                  setValue("rechedule_booking_count", 0);
                }}
              />
              {errors.start_date && (
                <p className="error-text">{errors.start_date.message}</p>
              )}
            </Form.Group> */}

            <div className="col-lg-11 mx-auto">
              <div className="form-bg">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div>
                      <label htmlFor="start_date" className="form-label">
                        Start Date
                      </label>
                      </div>
                    

                      <Controller
                        name="start_date"
                        control={control}
                        rules={{
                          required: "Start Date is required",
                          validate: (value) =>
                            value instanceof Date && !isNaN(value)
                              ? true
                              : "Please select a valid date",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            selected={field.value}
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                field.onChange(date);
                                setValue("start_date", date);
                                setValue("end_date", "");
                                setValue("start_time", "");
                                setValue("hours", "");
                                clearErrors("start_time"); // Clear start_time error when the date is changed
                                setIsCalculationDone(false);
                                setButtonOfPayment(false);
                              }
                            }}
                            autoComplete="off"
                            disabledKeyboardNavigation // Ensures no keyboard interaction
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={new Date()}
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "/",
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                              ];

                              if (!allowedKeys.includes(e.key)) {
                                e.preventDefault();
                              } else {
                                console.log(e.target.value);
                              }
                            }}
                          />
                        )}
                      />

                      {errors.start_date && (
                        <div className="text-danger">
                          {errors.start_date.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {data?.open_24_hours == 1 ? (
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                      <div>
                        <label htmlFor="end_date" className="form-label">
                          End Date
                        </label>
                        </div>
                        <Controller
                          name="end_date"
                          control={control}
                          rules={{
                            required: "End Date is required",
                          }}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => {
                                if (
                                  !startDate ||
                                  !(startDate instanceof Date)
                                ) {
                                  console.error(
                                    "Invalid startDate:",
                                    startDate
                                  );
                                  return;
                                }

                                const startDateOnly = new Date(
                                  startDate.getFullYear(),
                                  startDate.getMonth(),
                                  startDate.getDate()
                                );
                                const endDateOnly = new Date(
                                  date.getFullYear(),
                                  date.getMonth(),
                                  date.getDate()
                                );

                                // Calculate the difference in days
                                const days = Math.max(
                                  Math.ceil(
                                    (endDateOnly - startDateOnly) /
                                      (1000 * 60 * 60 * 24) +
                                      1
                                  ),
                                  1
                                );

                                // Update the form state
                                setValue("end_date", date);
                                setValue("days", days);
                                setValue("calculation_status", false);
                                field.onChange(date);
                                setIsCalculationDone(false);
                                setButtonOfPayment(false);
                              }}
                              autoComplete="off"
                              className="form-control"
                              placeholderText="MM/DD/YYYY"
                              minDate={startDate}
                              disabled
                            />
                          )}
                        />
                        {errors.end_date && (
                          <div className="text-danger">
                            {errors.end_date.message}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor="end_date" className="form-label">
                          End Date
                        </label>
                        <Controller
                          name="end_date"
                          control={control}
                          rules={{
                            required: "End Date is required",
                          }}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={(date) => {
                                if (
                                  !startDate ||
                                  !(startDate instanceof Date)
                                ) {
                                  console.error(
                                    "Invalid startDate:",
                                    startDate
                                  );
                                  return;
                                }

                                const startDateOnly = new Date(
                                  startDate.getFullYear(),
                                  startDate.getMonth(),
                                  startDate.getDate()
                                );
                                const endDateOnly = new Date(
                                  date.getFullYear(),
                                  date.getMonth(),
                                  date.getDate()
                                );

                                // Calculate the difference in days
                                const days = Math.max(
                                  Math.ceil(
                                    (endDateOnly - startDateOnly) /
                                      (1000 * 60 * 60 * 24) +
                                      1
                                  ),
                                  1
                                );

                                // Update the form state
                                setValue("end_date", date);
                                setValue("days", days);
                                setValue("calculation_status", false);
                                field.onChange(date);
                                setIsCalculationDone(false);
                                setButtonOfPayment(false);
                              }}
                              autoComplete="off"
                              className="form-control"
                              placeholderText="MM/DD/YYYY"
                              minDate={startDate}
                              disabledKeyboardNavigation
                              onKeyDown={(e) => {
                                const allowedKeys = [
                                  "Backspace",
                                  "/",
                                  "0",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                ];

                                if (!allowedKeys.includes(e.key)) {
                                  e.preventDefault();
                                } else {
                                  console.log(e.target.value);
                                }
                              }}
                            />
                          )}
                        />
                        {errors.end_date && (
                          <div className="text-danger">
                            {errors.end_date.message}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="start_time" className="form-label">
                        Start Time
                      </label>
                      <Controller
                        name="start_time"
                        control={control}
                        rules={{
                          required: "Start Time is required",
                        }}
                        render={({ field }) => (
                          <TimePicker
                            format="hh:mm aa"
                            showMeridiem
                            {...field}
                            value={field.value || null} // Ensure the value is correctly passed
                            className="form-control p-0"
                            onChange={(time) => {
                              const startDate = getValues("start_date");
                              const today = new Date();

                              // Convert `startDate` to only a Date without time for comparison
                              const selectedDate = new Date(startDate);
                              const isToday =
                                selectedDate.getDate() === today.getDate() &&
                                selectedDate.getMonth() === today.getMonth() &&
                                selectedDate.getFullYear() ===
                                  today.getFullYear();

                              if (isToday) {
                                const currentTime = new Date();
                                const [currentHours, currentMinutes] = [
                                  currentTime.getHours(),
                                  currentTime.getMinutes(),
                                ];

                                let selectedHours, selectedMinutes;

                                // Check if time is a string, then split
                                if (typeof time === "string") {
                                  [selectedHours, selectedMinutes] = time
                                    .split(":")
                                    .map(Number);
                                } else if (time instanceof Date) {
                                  selectedHours = time.getHours();
                                  selectedMinutes = time.getMinutes();
                                }

                                // Create Date objects for comparison
                                const currentDateTime = new Date();
                                currentDateTime.setHours(
                                  currentHours,
                                  currentMinutes,
                                  0
                                );

                                const selectedDateTime = new Date();
                                selectedDateTime.setHours(
                                  selectedHours,
                                  selectedMinutes,
                                  0
                                );

                                if (selectedDateTime < currentDateTime) {
                                  setError("start_time", {
                                    type: "manual",
                                    message:
                                      "Start time cannot be earlier than the current time.",
                                  });
                                  return;
                                } else {
                                  clearErrors("start_time");
                                }
                              }

                              field.onChange(time); // Update the field value
                              setValue("start_time", time);
                              setValue("calculation_status", false);
                              setValue("hours", "");
                              setValue("end_time", "");
                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            }}
                          />
                        )}
                      />
                      {errors.start_time && (
                        <div className="text-danger">
                          {errors.start_time.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="start_date" className="form-label">
                        Hours
                      </label>
                      <Controller
                        name="hours"
                        control={control}
                        rules={{
                          required: "Hours are required",
                          min: { value: 1, message: "Minimum value is 1" },
                        }}
                        render={({ field }) => (
                          <Form.Control
                            type="number"
                            name="hours"
                            placeholder="Enter Hours"
                            {...register("hours", {
                              required: "Valid Hours Is Required",
                            })}
                            onChange={(e) => {
                              const value = e.target.value;

                              // Ensure the input is an integer and less than or equal to 99
                              if (/^\d+$/.test(value)) {
                                // && parseInt(value) <= 99
                                let parsedValue = parseInt(value, 10);

                                // Ensure minimum value is 1
                                if (parsedValue < 1) {
                                  parsedValue = 1;
                                }

                                setValue("hours", parsedValue);
                                setValue("calculation_status", false);
                                field.onChange(parsedValue);

                                const startTime = getValues("start_time"); // Get start_time value
                                const startDateValue = getValues("start_date"); // Get start_date value

                                let formattedStartTime = startTime;
                                if (startTime instanceof Date) {
                                  formattedStartTime = startTime.toLocaleTimeString(
                                    [],
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  );
                                }

                                const startDate = new Date(startDateValue);

                                if (
                                  formattedStartTime &&
                                  !isNaN(startDate.getTime())
                                ) {
                                  const [
                                    timeWithoutAmPm,
                                    period,
                                  ] = formattedStartTime.split(" ");
                                  let [
                                    startHour,
                                    startMinute,
                                  ] = timeWithoutAmPm.split(":").map(Number);

                                  // Convert start time to 24-hour format
                                  if (period === "PM" && startHour !== 12) {
                                    startHour += 12;
                                  } else if (
                                    period === "AM" &&
                                    startHour === 12
                                  ) {
                                    startHour = 0;
                                  }

                                  startDate.setHours(startHour, startMinute);

                                  // Add the specified hours to calculate the end date and time
                                  const endDate = new Date(startDate);
                                  endDate.setHours(
                                    endDate.getHours() + parsedValue
                                  );

                                  const formattedEndTime = endDate.toString();

                                  // Handle date change logic
                                  if (data?.open_24_hours == 1) {
                                    setValue("end_date", endDate);
                                  }
                                  setValue("end_time", formattedEndTime);
                                } else {
                                  console.error(
                                    "Invalid startDate or startTime:",
                                    startDate,
                                    formattedStartTime
                                  );
                                }
                              } else {
                                field.onChange("");
                              }

                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            }}
                            className={classNames("", {
                              "is-invalid": errors?.hours,
                            })}
                          />
                        )}
                      />
                      {errors?.hours && (
                        <span className="invalid-feedback">
                          {errors.hours.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="capacity" className="form-label">
                        Capacity
                      </label>
                      {/* <Controller
                      name="capacity"
                      control={control}
                      rules={{
                        required: "Capacity is required",
                        // min: { value: 1, message: "Give Positive Value" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          placeholder="Enter Crew Capacity"
                          // min="1"
                          value={field.value || ""} // Ensures field can be cleared
                          onChange={(e) => {
                            let value = e.target.value;

                            // Ensure value is positive
                            if (value >= 0);

                            const parsedValue = parseFloat(value);

                            field.onChange(parsedValue); // Updates the form state
                            setValue("calculation_status", false);
                            setIsCalculationDone(false);
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.capacity,
                          })}
                        />
                      )}
                    /> */}

                      <Controller
                        name="capacity"
                        control={control}
                        rules={{
                          required: "Capacity is required",
                          validate: (value) =>
                            value > 0 || "Capacity must be a positive number",
                        }}
                        render={({ field }) => (
                          <Form.Control
                            type="number"
                            placeholder="Enter Crew Capacity"
                            value={field.value || ""} // Ensures field can be cleared
                            onChange={(e) => {
                              let value = e.target.value;

                              // Ensure value is a positive number
                              if (
                                value === "" ||
                                (parseFloat(value) > 0 && !isNaN(value))
                              ) {
                                const parsedValue = parseFloat(value);
                                field.onChange(parsedValue); // Updates the form state
                                setValue("calculation_status", false);
                                setIsCalculationDone(false);
                                setButtonOfPayment(false);
                              } else {
                                // Reset to previous valid value if not positive
                                e.target.value = field.value || "";
                              }
                            }}
                            className={classNames("", {
                              "is-invalid": errors?.capacity,
                            })}
                          />
                        )}
                      />

                      {errors?.capacity && (
                        <span className="invalid-feedback">
                          {errors.capacity.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="end_time" className="form-label">
                        End Time
                      </label>
                      <Controller
                        name="end_time"
                        control={control}
                        render={({ field }) => (
                          <TimePicker
                            {...field}
                            className="form-control p-0"
                            format="hh:mm a"
                            value={field.value ? new Date(field.value) : null}
                            disabled
                          />
                        )}
                      />
                      {errors.end_time && (
                        <div className="text-danger">
                          {errors.end_time.message}
                        </div>
                      )}
                    </div>

                    {chargesError && (
                      <div style={errorStyle}>{chargesError}</div>
                    )}
                  </div>
                  <div className="col-lg-9 col-md-6 text-end icresvaliderrr">
                    <p>{calculationError}</p>
                  </div>
                </div>{" "}
              </div>{" "}
            </div>

            <div className="col-lg-11 mx-auto mt-5">
              <div className="form-bg">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>Whole day Charges:</Form.Label>
                      <p className="charges-text-holder">
                        ₹ {data?.full_day_charges} ({data?.full_day_hours}{" "}
                        Hours)
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>Half day Charges:</Form.Label>
                      <p className="charges-text-holder">
                        ₹ {data?.half_day_charges} ({data?.half_day_hours}{" "}
                        Hours)
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>Hourly Charges:</Form.Label>
                      <p className="charges-text-holder">
                        ₹ {data?.hourly_basis_charges}
                      </p>
                    </div>
                  </div>{" "}
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>Start Time</Form.Label>
                      <p className="charges-text-holder">
                        {convertToAmPm(data?.full_d_start_time)}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>End Time</Form.Label>
                      <p className="charges-text-holder">
                        {convertToAmPm(data?.full_d_end_time)}
                      </p>
                    </div>
                  </div>{" "}
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <Form.Label>Studio crew Capacity</Form.Label>
                      <p className="charges-text-holder">
                        {data?.studio_crew_capacity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {rechedule_booking_count &&
            rechedule_booking_count <= 0 &&
            getValues("pay_amount") ? (
              <>
                <p style={{ color: "red", margin: "0", fontSize: "12px" }}>
                  Base Price : ₹{getValues("pay_amount")}.
                </p>
                <p style={{ color: "red", margin: "0", fontSize: "12px" }}>
                  Platform Charges : ₹{getValues("p_charge_p")}.
                </p>
                <p style={{ color: "red", margin: "0", fontSize: "12px" }}>
                  GST : ₹{getValues("gst_charges")}.
                </p>
                <p style={{ color: "red", margin: "0", fontSize: "12px" }}>
                  You need to pay ₹{getValues("total")} for the reschedule.
                </p>
              </>
            ) : (
              <></>
            )} */}

            {
              <div className="row justify-content-end">
                <div className="col-md-6 col-12">
                  <div className="Bifurcation_charges_text">
                    <div className="Bifurcation_content-holder">
                      <div className="text-holder">
                        <h4>Days:-</h4>
                        <span> {details?.days}</span>
                      </div>
                      <div className="text-holder">
                        <h4>
                          Full Day Charges x ({details?.charges?.full_h_c}) :-
                        </h4>
                        <span>₹ {details?.charges?.full_h_p}</span>
                      </div>
                      <div className="text-holder">
                        <h4>
                          Half Day Charges x ({details?.charges?.half_h_c}) :-
                        </h4>
                        <span>₹ {details?.charges?.half_h_p}</span>
                      </div>
                      <div className="text-holder">
                        <h4>
                          Hourly Basis Charges x ({details?.charges?.min_h_c})
                          :-
                        </h4>
                        <span>₹ {details?.charges?.min_h_p}</span>
                      </div>

                      <div className="text-holder">
                        <h4>Add On Charges:-</h4>
                        <span>₹ {details?.add_one_price}</span>
                      </div>

                      

                      <hr></hr>

                      <div className="text-holder">
                        <h4>Base Total:-</h4>
                        <span>₹ {details?.base_price}</span>
                      </div>

                      {/* <div className="text-holder">
                                        <h4>Base Price Discount:-</h4>
                                        <span>
                                          ₹ {details?.base_price_discount}%
                                        </span>
                                      </div> */}

                      <div className="text-holder">
                        <h4>
                          Base Price Discount(
                          {details?.base_price_discount
                            ? details?.base_price_discount + " %"
                            : ""}
                          ) :-
                        </h4>
                        <span>- ₹ {details?.basepricediscountrupees}</span>
                      </div>

                      <div className="text-holder">
                        <h4>
                          Free Hours(
                          {details?.free_h_p_discount != 0 &&
                            userSubscription?.subscription?.subscription_detail
                              ?.free_booking_hours}
                          ):-
                        </h4>
                        <span>- ₹ {details?.free_h_p_discount}</span>
                      </div>

                      <div className="text-holder">
                        <h4>After Offer :-</h4>
                        <span> ₹ {details?.afteroffer}</span>
                      </div>
                      <div className="text-holder">
                        <h4>Reshedule Charges:-</h4>
                        <span>₹ {details?.reshedule_charges}</span>
                      </div>

                      <div className="text-holder">
                        <h4>
                          {" "}
                          PlatFrom Charges(
                          {details?.platform_charge}%)
                        </h4>
                        <span>+ ₹ {details?.p_charge_p}</span>
                      </div>
                      

                      <div className="text-holder">
                        <h4> GST({details?.gst}%) :-</h4>
                        <span>+ ₹ {details?.gstchargestoshow}</span>
                      </div>

                      <div className="text-holder">
                        <h4>Total:-</h4>
                        <span>₹ {details?.total}</span>
                      </div>

                      {/* <div className="text-holder">
                        <h4>Remaing Charges:-</h4>
                        <span>₹ {details?.reaming_price}</span>
                      </div>
                      <div className="text-holder">
                        <h4>Give Charges:-</h4>
                        <span>₹ {details?.give_price}</span>
                      </div> */}

                    </div>

                    <div className="col-lg-9 col-md-6 text-end icresvaliderrr">
                      <p>{calculationError}</p>
                    </div>

                    {/* <div className="calculate-btn-holder">
                      {!isCalculationDone && (
                        <button
                          type="button"
                          className="calculate-btn"
                          disabled={isButtonDisabled}
                          onClick={() => {
                            clearErrors("calculation_status");
                            handleSubmit(onSubmitCalculation)();
                          }}
                        >
                          Check Availability
                        </button>
                      )}

                      {errors.calculation_status && (
                        <div className="text-danger">
                          {errors.calculation_status.message}
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            }

            {loading ? (
              <div className="text-center mt-4 mb-4">
                <ColorRing
                  className="blocks-wrapper"
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            ) : isCalculationDone ? (
              <div className="text-center mt-4 mb-4">
                {Number(details?.reaming_price) <= 0 ? (
                  <button
                    className="save-btnnnn me-2"
                    type="button"
                    onClick={(e) => finalFunction(getValues(),)}
                  >
                    Reschedule Booking
                  </button>
                ) : userdata?.amount < details?.total ? (
                  <button
                    className="save-btnnnn me-2"
                    type="button"
                    onClick={() => {
                      handleRazorpay(getValues(), details?.reaming_price);
                    }}
                  >
                    Pay for Proceed
                  </button>
                ) : (
                  <button
                    className="save-btnnnn me-2"
                    type="button"
                    onClick={() => {
                      setModalWalleteShow(true);
                    }}
                  >
                    Pay for Proceed
                  </button>
                )}

                <button
                  className="close-buttonnn"
                  type="button"
                  onClick={props.onHide}
                >
                  Close
                </button>
              </div>
            ) : (
              <div className="text-center mt-4 mb-4">
                <button
                  className="save-btnnnn me-2"
                  type="button"
                  onClick={() => {
                    handleSubmit(onSubmitCalculation)();
                  }}
                >
                  Check
                </button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <Reshedule_Wallet_Payment_modal
        handleSubmit={handleSubmit(onSelecteSubmit)}
        loading={loading}
        setPaymentType={setPaymentType}
        setLoading={setLoading}
        show={modalWalletShow}
        onHide={() => setModalWalleteShow(false)}
      />
    </div>
  );
};

export default RescheduleModal;
