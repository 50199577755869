import React, { useContext, useState, Component } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { CheckExist, emailOtpGet } from "../../utils/apis/myAccount/myAccount";
import "./loginform.css";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faHouse } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import OtpInput from "react-otp-input";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import OtpTimer from "otp-timer";

import { userLogin } from "../../utils/apis/login/Login";
import {
  resendMobileOTP,
  sendEmail_ContactNoOTP,
  sendEmailOTP,
  sendMobileOTP,
  verifyEmailOTP,
  verifyMobileOTP,
} from "../../utils/apis/common/Common";
import { Errors } from "../../utils/errors";
const Forgot_password = () => {
  const [otp, setOtp] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      email_or_contact: "",
      send_otp: false,
      otp: "",
      verify_otp: false,
    },
  });

  const OTP = watch("otp");
  const SendOTP = watch("send_otp");
  const VerifyOTP = watch("verify_otp");

  const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(
    Context
  );

  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [sendOtpError, setSendError] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [otpLoader, setOtpLoader] = useState(false);
  const handleSendOtp = async (data) => {
    const isNumber = /^\d+$/.test(data.email_or_contact);

    setOtpLoader(true);

    const res = await sendEmail_ContactNoOTP(data.email_or_contact);
    if (res?.success) {
      setValue("send_otp", true);
      setModalShow2(true);
      setSeconds(60);
      clearErrors();
      setSendError(false);
    } else {
      setError("email_or_contact", {
        message: "User Not Found With This E-mail",
      });
    }
    setOtpLoader(false);
  };

  const navigate = useNavigate();
  const onSubmit = async (data) => {

    if (data.verify_otp) {
      navigate(`/set-password/${data.email_or_contact}`);
    } else {
      setError("email_or_contact", {
        message: "Please Varify OTP",
      });
    }
  };

  const verifyOTP = async (data) => {
    const isNumber = /^\d+$/.test(data.email_or_contact);
    let res;
    if (isNumber) {
      res = await verifyMobileOTP(data.email_or_contact, data.otp);
    } else {
      res = await verifyEmailOTP(data.email_or_contact, data.otp);
    }

    if (res?.success) {
      await setValue("verify_otp", true);
      await setValue("send_otp", false);
    } else {
      setError("email_or_contact", {
        message: res?.message,
      });
    }
  };

  return (
    <>
      <section className="login-page-main-sec">
        <div className="container main_form_container_holder">
          <div className="main-position-div">
            <div className="d-flex aligndiv">
              <div className="mid-img-div">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/login/mid-img2.png"
                  }
                />
              </div>

              <div className="last-div slidein margin-top">
                <div className="loginformsec topspace">
                  <Link to="/login">
                    <FontAwesomeIcon
                      className="home-icn"
                      icon={faChevronLeft}
                    />
                  </Link>
                  <h1 className="login-title">Reset Your Password</h1>
                  <p className="loginby">
                    You will receive an email from us requesting you to reset
                    your password.
                  </p>

                  <div className="form-div ">
                    <Form>
                      <div className="scrollable_form_holder">
                        <Form.Group className="form-group resetpassformtop">
                          <Form.Label>Email or Mobile Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="email_or_contact"
                            placeholder="Email / Mobile Number"
                            {...register("email_or_contact", {
                              required: "Email or contact number is required",
                              validate: (value) => {
                                const isNumber = /^\d+$/.test(value); // Check if the value is numeric
                                if (isNumber) {
                                  return (
                                    value.length === 10 ||
                                    "Invalid mobile number. It should be 10 digits."
                                  );
                                } else {
                                  // Validate as an email
                                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  return (
                                    emailRegex.test(value) ||
                                    "Invalid email address."
                                  );
                                }
                              },
                            })}
                            onChange={async (e) => {
                              await setValue(
                                "email_or_contact",
                                e.target.value
                              );
                              await setValue("send_otp", false);
                              await setValue("verify_otp", false);
                            }}
                            className={classNames({
                              "is-invalid": errors?.email_or_contact,
                              "is-valid": getValues("email_or_contact"),
                            })}
                          />
                          {errors?.email_or_contact && (
                            <span
                              // className="text-danger"
                              style={{ fontSize: "12px", color: "red" }}
                            >
                              {errors?.email_or_contact?.message}
                            </span>
                          )}

                          {!VerifyOTP ? (
                            !SendOTP ? (
                              <button
                                type="button"
                                className="sendotpbtn"
                                onClick={handleSubmit(handleSendOtp)}
                              >
                                Send OTP
                              </button>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </Form.Group>

                        {SendOTP && (
                          <Form.Group className="form-group otpinputmain">
                            <Controller
                              name="otp"
                              control={control}
                              rules={{
                                required: "OTP is required",
                                validate: (value) =>
                                  value?.length === 4 ||
                                  "OTP must be 4 digits long.",
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <>
                                  <OtpInput
                                    value={value || ""} // Sync value with form state
                                    onChange={(otp) => {
                                      onChange(otp); // Update the form state
                                    }}
                                    numInputs={4} // Number of OTP input fields
                                    renderSeparator={<span>-</span>} // Separator between inputs
                                    renderInput={(props) => (
                                      <input {...props} />
                                    )} // Custom input rendering
                                    className="me-2"
                                  />
                                  {/* Display validation error */}
                                  {error && (
                                    <span className="text-danger">
                                      {error.message}
                                    </span>
                                  )}
                                </>
                              )}
                            />

                            {SendOTP && (
                              <button
                                type="button"
                                className="sendotpbtn"
                                onClick={handleSubmit(verifyOTP)}
                              >
                                Verify OTP
                              </button>
                            )}

                            {/* OTP Timer */}
                            {/* <OtpTimer seconds={seconds} minutes={0} /> */}
                          </Form.Group>
                        )}
                      </div>

                      {/* <button className='Login-btn resetpasstop'>Submit</button> */}

                      <button
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                        className="Login-btn resetpasstop"
                      >
                        Submit
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgot_password;
