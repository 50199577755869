import React, { useEffect, useRef, useState, useContext } from "react";
import "./home-counter.css";

import CountUp from "react-countup";
import { Context } from "../../../utils/context";
import {
  aboutUsCounter,
  AllCategory,
  AllHappyCustomer,
  AllStudio,
  AllSuccessfulProject,
  MakeUsSpecial,
} from "../../../utils/apis/Home/Homeapi";
const Counter = () => {
  const [data, setData] = useState();
  const [happyCustomer, setHappyCustomer] = useState();
  const [successfulProject, setSuccessfulProject] = useState();
  const [studio, setStudio] = useState();
  const [category, setCategory] = useState();
  const [all, setall] = useState();

  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await MakeUsSpecial();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllHappyCustomer = async () => {
    const res = await AllHappyCustomer();
    if (res?.success) {
      setHappyCustomer(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllSuccessfulProject = async () => {
    const res = await AllSuccessfulProject();
    if (res?.success) {
      setSuccessfulProject(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllStudio = async () => {
    const res = await AllStudio();
    if (res?.success) {
      setStudio(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllCategory = async () => {
    const res = await AllCategory();
    if (res?.success) {
      setCategory(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllInOne = async () => {
    const res = await aboutUsCounter();
    if (res?.success) {
      setall(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  useEffect(() => {
    getAllInOne();
    getAllData();
    getAllHappyCustomer();
    getAllSuccessfulProject();
    getAllStudio();
    getAllCategory();
  }, []);
  return (
    <>
      <section className="Counter_sec">
        <div className="container">
          <div className="counter-bg-div">
            <div className="row">
              <div className="col-md-3 col-6  mb-3 aligndiv">
                {/* <h1 className='count-text'>1.5<span>K</span></h1> */}
                <div>
                  <div>
                    {" "}
                    <h1>
                      {all?.happyclients >= 1000 ? (
                        <>
                          <CountUp
                            className="count-text"
                            delay={2}
                            end={all?.happyclients / 1000}
                            decimals={1} // Show one decimal place
                          />
                          <span className="k_label">K</span>
                        </>
                      ) : (
                        <CountUp
                          className="count-text"
                          delay={2}
                          end={all?.happyclients}
                        />
                      )}
                      <span className="spank"></span>
                    </h1>
                  </div>
                  <div>
                    <p className="subtext">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6  mb-3 aligndiv">
                {/* <h1 className='count-text'>2.9<span>K</span></h1> */}
                <div>
                  <div>
                    <h1>
                      <CountUp
                        className="count-text"
                        delay={2}
                        end={all?.successfulproject}
                      />
                      <span className="spank"></span>
                    </h1>
                  </div>
                  <div>
                    <p className="subtext">Successful Projects</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6   aligndiv">
                {/* <h1 className='count-text'>20<span>+</span></h1> */}
                <div>
                  <div>
                    <h1>
                      <CountUp
                        className="count-text"
                        delay={2}
                        end={all?.totalstudio}
                      />
                      <span className="spank">+</span>
                    </h1>
                  </div>
                  <div>
                    <p className="subtext">Total Studios</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6  aligndiv">
                {/* <h1 className='count-text'>9<span>+</span></h1> */}
                <div>
                  <div>
                    <h1>
                      <CountUp
                        className="count-text"
                        delay={2}
                        end={all?.totalcategories}
                      />
                      <span className="spank">+</span>
                    </h1>
                  </div>
                  <div>
                    {" "}
                    <p className="subtext">Total Categories</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;
