import React, { useState, useEffect, useContext, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faMagnifyingGlass,
  faShoppingCart,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { Col, NavDropdown, Row, Tab } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import Signup from "../login/Signup";
import { FAQdata } from "../../utils/apis/master/Master";
import { SpaceStidio } from "../../utils/apis/Home/Homeapi";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import { UserGet } from "../../utils/apis/myAccount/myAccount";
import WalletBalanceModal from "../common-section/Modals/WalletBalanceModal/WalletBalanceModal";
function Header() {
  const Vendor = "Vendor";

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const treatments = [
    {
      name: "Dental Fillings",
    },
    {
      name: "Root Canal Treatment",
    },
    {
      name: "Orthodontic Treatment (Invisalign, Aligners, Braces)",
    },
    {
      name: "Dental Crown & Bridges",
    },
    {
      name: "Tooth Extraction / Tooth Removal",
    },
    {
      name: "Pediatrics Dental Treatments",
    },
    {
      name: "Acne & Acne Scar Treatment",
    },
    {
      name: "Tan Removal Treatment",
    },
    {
      name: "Moles Removal",
    },
    {
      name: "Pre - Bridal Treatment",
    },
    {
      name: "Hydra Facial",
    },
  ];
  const { setSignin, signin, userdata, setUserData } = useContext(Context);

  const [show, setShow] = useState(false);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  // const handleClose = () => setShow(false);
  const handleClose = () => {
    setShow(false); // Close the modal
    setSearch("");
    setDataFAQ([]); // Clear the search input
  };
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await Cookies.remove("flick_N_shoot_security");
    await setSignin(false);
    // Show the modal

    await navigate("/login");
  };

  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await SpaceStidio();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleSelect = (href) => {
    navigate(href);
  };

  const [dataFAQ, setDataFAQ] = useState();

  const getAllDataFAQ = async (search) => {
    setSearch(search);
    const res = await FAQdata(search);
    if (res?.success) {
      setDataFAQ(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  // useEffect(() => {
  //   if(search){
  //     getAllDataFAQ();
  //   }
  // }, [search]);

  const [activeTab, setActiveTab] = useState("first");

  const handleIconClick = () => {
    navigate("/account", { state: { activeTab: "fiveth" } });
  };

  return (
    <>
      <section className="main-head-sec">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={process.env.PUBLIC_URL + "/assets/images/logo/logo.png"}
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <div></div>
              <Nav className="">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/aboutus"
                  className={location.pathname === "/aboutus" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  About Us
                </Nav.Link>

                <NavDropdown
                  title={<> Studios </>}
                  className={
                    location.pathname === "/media-spaces"
                      ? "active studiodrop"
                      : "studiodrop"
                  }
                  id="basic-nav-dropdown"
                >
                  {data?.map((val, index) => (
                    <NavDropdown.Item
                      onClick={() => handleSelect(`/media-spaces/${val?.id}`)}
                    >
                      <div className="d-flex">
                        <div className="img-div">
                          <img className="menuimg" src={IMG_URL + val?.image} />
                        </div>
                        <div className="menunamediv my-auto">
                          <p>{val?.name}</p>
                        </div>
                      </div>
                    </NavDropdown.Item>
                  ))}

                  {/* <NavDropdown.Item href="/media-spaces">
                    <div className="d-flex">
                      <div className="img-div">
                        <img className="menuimg" src={process.env.PUBLIC_URL + '/assets/images/header/item1.png'} />
                      </div>
                      <div className="menunamediv my-auto">
                        <p>Speak Easy Lounge</p>
                      </div>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item href="/media-spaces">
                    <div className="d-flex">
                      <div className="img-div">
                        <img className="menuimg" src={process.env.PUBLIC_URL + '/assets/images/header/item1.png'} />
                      </div>
                      <div className="menunamediv my-auto">
                        <p>Podcast Lounge</p>
                      </div>
                    </div>
                  </NavDropdown.Item> */}
                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to="/subscription"
                  className={
                    location.pathname === "/subscription" ? "active" : ""
                  }
                  onClick={handleNavItemClick}
                >
                  Subscription
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/contact"
                  className={location.pathname === "/contact" ? "active" : ""}
                  onClick={handleNavItemClick}
                >
                  Contact Us
                </Nav.Link>

                <div className="deskviewserchacc">
                  <div className="all-icon">
                    <div className="glassicndiv" onClick={handleShow}>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="glassicn"
                      />
                    </div>
                    {signin == true && (
                      <div className="glassicndiv d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faWallet}
                          className="glassicn"
                          onClick={handleIconClick} // Navigate to /account on click
                        />
                        <p className=" ms-3 m-0">₹ {userdata?.amount} </p>
                      </div>
                    )}
                    <div className="userdiv">
                      <FontAwesomeIcon icon={faUser} className="usericn" />
                      <NavDropdown title="" id="basic-nav-dropdown">
                        {signin ? (
                          <>
                            <NavDropdown.Item
                              onClick={() => handleSelect(`/account`)}
                            >
                              Profile
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout}>
                              Log Out
                            </NavDropdown.Item>
                          </>
                        ) : (
                          <>
                            <NavDropdown.Item
                              onClick={() => handleSelect(`/login`)}
                            >
                              Log In
                            </NavDropdown.Item>

                            <NavDropdown.Item
                              onClick={() => handleSelect(`/sign-up/Customer`)}
                            >
                              Sign Up
                            </NavDropdown.Item>
                          </>
                        )}
                        {/* <NavDropdown.Item href="/login">Log In</NavDropdown.Item>
                        <NavDropdown.Item href="/login">Log Out</NavDropdown.Item>
                        <NavDropdown.Item href="/sign-up">Sign Up</NavDropdown.Item>
                        <NavDropdown.Item href="/account">Profile</NavDropdown.Item> */}
                      </NavDropdown>
                    </div>
                    <Link
                      // to={{
                      //   pathname: "/sign-up",
                      //   search: Vendor,
                      // }}
                      to={"/sign-up/Vendor"}
                      className="listdtubtn"
                    >
                      Join Us As Vendor
                    </Link>{" "}
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
            {signin ? (
              <div class="hello-text ms-3">
                <h3> {userdata ? `Hello ${userdata?.first_name} ` : ""}</h3>
              </div>
            ) : (
              ""
            )}

            <div className="mobviewserchacc">
              <div className="all-icon ">
                <div className="glassicndiv" onClick={handleShow}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="glassicn"
                  />
                </div>
                {signin == true && (
                  <div className="glassicndiv d-flex align-items-center">
                    <FontAwesomeIcon icon={faWallet} className="glassicn" />
                    <p className=" ms-3 m-0">{userdata?.amount} Rs</p>
                  </div>
                )}

                <div className="userdiv">
                  <FontAwesomeIcon icon={faUser} className="usericn" />

                  <NavDropdown title="" id="basic-nav-dropdown">
                    {signin ? (
                      <>
                        <NavDropdown.Item
                          onClick={() => handleSelect(`/login`)}
                        >
                          Log Out
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          onClick={() => handleSelect(`/account`)}
                        >
                          Profile
                        </NavDropdown.Item>
                      </>
                    ) : (
                      <>
                        <NavDropdown.Item
                          onClick={() => handleSelect(`/login`)}
                        >
                          Log In
                        </NavDropdown.Item>

                        <NavDropdown.Item
                          onClick={() => handleSelect(`/sign-up/Customer`)}
                        >
                          Sign Up
                        </NavDropdown.Item>
                      </>
                    )}
                  </NavDropdown>
                </div>
              </div>
            </div>
          </Navbar>

          {["top"].map((placement, idx) => (
            <Offcanvas
              className="headsearchoffcanvas"
              show={show}
              onHide={handleClose}
              placement={placement}
              name={placement}
            >
              <div className="container">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <h5 className="offtitle">WHAT ARE YOU LOOKING FOR?</h5>

                  <div className="searchdiv">
                    {/* <input
                      className="form-control"
                      type="search"
                      placeholder="Enter your search"
                    /> */}
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={search}
                      onChange={(e) => {
                        getAllDataFAQ(e.target.value);
                      }}
                    />
                  </div>

                  <h5 className="offtitle">OR IS YOUR QUESTION LISTED HERE?</h5>

                  {/* <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        How can I change my personal information?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                  {dataFAQ?.map((faq, index) => (
                    <Accordion defaultActiveKey={data[0]?.id} key={index}>
                      <Accordion.Item eventKey={faq?.id}>
                        <Accordion.Header>{faq?.name}</Accordion.Header>
                        <Accordion.Body>
                          {parse(faq?.description)}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}

                  <Link to="/faq">
                    <button className="viewallbtn btn" onClick={handleClose}>
                      View All FAQs
                    </button>
                  </Link>
                </Offcanvas.Body>
              </div>
            </Offcanvas>
          ))}
        </Container>
      </section>

      {/* Use WalletModal Component */}
      {/* <WalletBalanceModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        walletBalance={walletBalance}
      /> */}
    </>
  );
}

export default Header;
