import { useState, useEffect } from "react";
import "../../bookings-form.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../../../utils/common";
import Accordion from "react-bootstrap/Accordion";
import Success_modal from "../../../common-section/Modals/Success_modal/Success_modal";
import classNames from "classnames";
import useRazorpay from "react-razorpay";
import { getAdditionalSupport } from "../../../../utils/apis/master/Master";
import { postBooking } from "../../../../utils/apis/booking/booking";
const Hourly_form = ({ data }) => {
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [value, onChange] = useState("10:00");

  const [phoneValue, setPhoneValue] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm();

  const [duration, setDuration] = useState(0);
  const [studioId, seStudioId] = useState();
  // Watch start_time and end_time fields
  const startTime = watch("start_time");
  const endTime = watch("end_time");

  const [totalCharge, setTotalCharge] = useState(0);
  useEffect(() => {
    seStudioId(data?.id);
    if (startTime && endTime) {
      const start = new Date(`1970-01-01T${startTime}`);
      const end = new Date(`1970-01-01T${endTime}`);
      const diff = (end - start) / (1000 * 60 * 60); // difference in hours
      setDuration(diff);
      if (diff >= 2 && diff < 4) {
        setTotalCharge(Math.round(diff * data?.hourly_basis_charges));
        clearErrors("end_time");
        // Clear any errors related to end_time
      } else if (diff == 4) {
        setTotalCharge(Math.round(data?.half_day_charges));
      } else if (diff == 9) {
        setTotalCharge(Math.round(data?.full_day_charges));
      } else {
        setError("end_time", {
          type: "manual",
          message: "End time must be at least 2 hours greater than start time",
        });
      }
    }
  }, [startTime, endTime, setError, clearErrors]);

  const selectedAddOns = watch("add_on_id");
  const handleCheckboxChange = (id, checked) => {
    const currentAddOns = selectedAddOns || [];
    if (checked) {
      setValue("add_on_id", [...currentAddOns, id]);
    } else {
      setValue(
        "add_on_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
    }
  };

  const selectedsupport = watch("additional_support_id");

  const handleCheckboxChangeSupport = (id, checked) => {
    const currentAddOns = selectedsupport || [];
    if (checked) {
      setValue("additional_support_id", [...currentAddOns, id]);
    } else {
      setValue(
        "additional_support_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
    }
  };

  const [dataSupport, setDataSupport] = useState();

  const getAllData = async () => {
    const res = await getAdditionalSupport();
    if (res?.success) {
      setDataSupport(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const onSubmit = (datafinal) => {
    const selectedDate = new Date(datafinal.date).toISOString().split("T")[0];
    const { start_time, end_time } = datafinal;

    // Check for booking conflicts
    const isBooked = data?.book_studios?.some((booking) => {
      const bookingStartDate = new Date(booking.start_date)
        .toISOString()
        .split("T")[0];
      const bookingEndDate = new Date(booking.end_date)
        .toISOString()
        .split("T")[0];
      const bookingDate = new Date(booking.date).toISOString().split("T")[0];

      // Check if the selected date falls within the booking date range
      const isDateOverlap =
        (bookingStartDate &&
          bookingEndDate &&
          selectedDate >= bookingStartDate &&
          selectedDate <= bookingEndDate) ||
        (bookingDate && selectedDate === bookingDate);

      const isTimeOverlap =
        (start_time >= booking.start_time && start_time < booking.end_time) ||
        (end_time > booking.start_time && end_time <= booking.end_time) ||
        (start_time <= booking.start_time && end_time >= booking.end_time);

      return isDateOverlap && isTimeOverlap;
    });

    if (isBooked) {
      setError("end_time", {
        type: "manual",
        message: "This time slot is already booked.",
      });
    } else {
      clearErrors("end_time");
      handleRazorpay(datafinal); // Handle form submission
      // reset(); // Uncomment this to reset form fields after successful submission
    }
  };

  // const onSubmit = (datafinal) => {
  //   const selectedDate = new Date(datafinal.date).toISOString().split('T')[0];
  //   const { start_time, end_time } = datafinal;

  //   // Check for booking conflicts
  //   const isBooked = data?.book_studios?.some(booking =>
  //     booking.date === selectedDate &&
  //     ((start_time >= booking.start_time && start_time < booking.end_time) ||
  //     (end_time > booking.start_time && end_time <= booking.end_time) ||
  //     (start_time <= booking.start_time && end_time >= booking.end_time))
  //   );

  //   if (isBooked) {
  //     setError('end_time', {
  //       type: 'manual',
  //       message: 'This time slot is already booked.'
  //     });
  //   } else {
  //     clearErrors('end_time');
  //     handleRazorpay(datafinal); // Uncomment this to handle form submission
  //     // reset(); // Uncomment this to reset form fields after successful submission
  //   }
  // };
  const handleRazorpay = async (data) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(totalCharge) * 100,
      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(data, res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (data, payment_id) => {
    const datafinal = {
      studio_id: studioId,
      type: "Hourly",
      start_time: data?.start_time,
      end_time: data?.end_time,
      date: data?.date,
      name: data?.name,
      amount: totalCharge,
      payment_id: payment_id,
      email: data?.email,
      contact_no: data?.contact_no,
      description: data?.description,
      add_on_id: data?.add_on_id,
      additional_support_id: data?.additional_support_id,
      support_description: data?.support_description,
    };

    const res = await postBooking(datafinal);
    if (res?.success) {
      reset();
      setModalShow(true);
      // setTimeout(() => {
      //   setModalShow(false);
      //     // window.location = "/home";
      // }, 3000)
    }
  };
  return (
    <>
      <div className="booking-from-sec">
        <div className="row">
          <div className="col-lg-9 col-md-12 mx-auto">
            <Form>
              <div className="form-bg">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <Controller
                        name="date"
                        control={control}
                        defaultValue={new Date()}
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={new Date()}
                          />
                        )}
                      />
                      {errors.end_time && (
                        <div className="text-danger">
                          {errors.end_time.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <Controller
                        name="start_time"
                        control={control}
                        // defaultValue="00:00"
                        render={({ field }) => (
                          <TimePicker
                            className="form-control"
                            onChange={(time) => {
                              field.onChange(time);
                              setValue("start_time", time); // Update the form value
                            }}
                            value={field.value}
                            disableClock={true}
                            // clearIcon={null}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <Controller
                        name="end_time"
                        control={control}
                        // defaultValue="00:00"
                        render={({ field }) => (
                          <TimePicker
                            className="form-control"
                            onChange={(time) => {
                              field.onChange(time);
                              setValue("end_time", time); // Update the form value
                            }}
                            value={field.value}
                            disableClock={true}
                            // clearIcon={null}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="estimatedh6">
                Estimated Booking Cost: ₹ {totalCharge}/-
              </h6>

              <div className="form-bg">
                <div className="form-group">
                  <Form.Label>Enter Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    {...register("name", {
                      required: "Name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.name,
                      "is-valid": getValues("name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="form-group">
                  <Form.Label>Enter Email*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                      "is-valid": getValues("email"),
                    })}
                  />
                  {errors?.email && (
                    <sup className="text-danger">{errors?.email?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Controller
                    name="contact_no"
                    control={control}
                    rules={{
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    }}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Project Description*</Form.Label>
                  {/* <Form.Control as="textarea" placeholder="Type here..." /> */}
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="name"
                    placeholder="Enter Description"
                    {...register("description", {
                      required: "Description required",
                    })}
                  />
                </div>
              </div>

              <div className="form-bg mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Add Ons<span> (Optional)</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-opn mb-3">
                        <div className="add-one">
                          <h6 className="addh6">ADD-ONS</h6>
                          <Controller
                            name="add_on_id"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <>
                                {data?.studio_add_ons?.map((val) => (
                                  <Form.Check
                                    key={val.id} // Assuming `id` is the unique identifier
                                    type="checkbox"
                                    label={`${val.add_on.name} + ${val.rate}`}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        val.id,
                                        e.target.checked
                                      )
                                    }
                                    checked={field.value.includes(val.id)}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>

                      <div className="acc-opn">
                        <div className="add-one">
                          <h6 className="addh6">ADDITIONAL SUPPORT</h6>
                          <Form>
                            <Controller
                              name="additional_support_id"
                              control={control}
                              defaultValue={[]} // Ensure default value is an empty array
                              render={({ field }) => (
                                <>
                                  {dataSupport?.map((val) => (
                                    <Form.Check
                                      key={val.id} // Assuming `id` is the unique identifier
                                      type="checkbox"
                                      label={`${val.name}`}
                                      onChange={(e) =>
                                        handleCheckboxChangeSupport(
                                          val.id,
                                          e.target.checked
                                        )
                                      }
                                      checked={field.value.includes(val.id)} // Ensure field.value is an array
                                    />
                                  ))}
                                </>
                              )}
                            />

                            <div className="form-group mt-5">
                              <Form.Label className="addlabhh">
                                Add Additional Support
                              </Form.Label>
                              {/* <Form.Control
                                as="textarea"
                                placeholder="Type here..."
                              /> */}
                              <Form.Control
                                as="textarea"
                                type="text"
                                name="name"
                                placeholder="Enter Description"
                                {...register("support_description", {})}
                              />
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="text-center mt-5">
                <buttton
                  className="proceed-btn"
                  onClick={handleSubmit(onSubmit)}
                  // onClick={() => setModalShow(true)}
                >
                  Proceed to Pay
                </buttton>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Success_modal
        maintext={"Booking Successfully"}
        smalltext={"Soon you will be notified by email & sms."}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Hourly_form;
