import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./FilterModal.css";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { highestStudioPrice } from "../../../../utils/apis/Home/Homeapi";
import Slider from "@mui/material/Slider";

const FilterModal = ({
  setLocation,
  setPrice,
  setRating,
  modalShow,
  setModalShow,
  ...props
}) => {
  const [studioPrice, setStudioPrice] = useState(1000);
  const [priceValue, setPriceValue] = useState({ min: 0, max: studioPrice });
  const [distanceValue, setDistanceValue] = useState({ min: 0, max: 100 });
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocationState] = useState("");
  const [price, setPriceState] = useState("");
  const [rating, setRatingState] = useState("");


  const minValue = 0;
  const maxValue = studioPrice || 1000;

  // useEffect(() => {
  const getAllData = async () => {
    const res = await highestStudioPrice();
    if (res?.success) {
      // modalShow(false);
      // alert("");
      setModalShow(false);
      setStudioPrice(res?.data?.data);
      setPriceValue({ min: minValue, max: res?.data?.data });
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  // }, []);

  const handleSearch = () => {
    setLocation({ location, distanceRange: distanceValue });
    getAllData();
  };

  const handleClear = () => {
    setLocationState("");
    // setLocation({
    //   location: "",
    //   distanceRange: { min: minValue, max: "" },
    // });
    setPriceState("");
    setRatingState("");
    setRating({ rating: "" });
    setPrice({ price: "", priceRange: "" });
    setLocation({
      location: "",
      distanceRange: { min: minValue, max: "" },
    });
    setPriceValue({ min: minValue, max: maxValue }); // Resetting the range bar
    setDistanceValue({ min: 0, max: 100 }); // Reset distance
  };

  const handleSearchPrice = () => {
    setPrice({ price, priceRange: priceValue });
    getAllData();
  };

  const handleClearPrice = () => {
    setPriceState("");
    setPrice({ price: "", priceRange: "" });
    setPriceValue({ min: minValue, max: maxValue });
  };

  const handleSearchRating = () => {
    setRating({ rating });
    getAllData();
  };

  const handleClearRatings = () => {
    setRatingState("");
    setPriceState("");
    setLocation({
      location: "",
      distanceRange: { min: minValue, max: "" },
    });
    setRating({ rating: "" });
    setPrice({ price: "", priceRange: "" });
    setPriceValue({ min: minValue, max: maxValue }); // Resetting the range bar
    setDistanceValue({ min: 0, max: 100 }); // Reset distance
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={3} md={4}>
              <div className="bg-color">
                <div className="heading-name mb-4">
                  <h2>Sort By</h2>
                </div>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Location</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="border-line">
                      Pricing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="border-line">
                      Ratings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col lg={9} md={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="location-bg">
                    <Row>
                      <Col lg={5}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="locationInput"
                          >
                            <Form.Label>Desired Location</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Studio location/Address"
                              value={location}
                              onChange={(e) => setLocationState(e.target.value)}
                            />
                          </Form.Group>
                          <div className="btn-holder">
                            <Button
                              className="btn-search"
                              onClick={handleSearch}
                            >
                              SEARCH
                            </Button>
                            <Button className="btn-clear" onClick={handleClear}>
                              CLEAR
                            </Button>
                          </div>
                        </Form>
                      </Col>
                      {props?.latitude ? (
                        <>
                          {" "}
                          <Col lg={2}>
                            <div className="circle-orr text-center">
                              <p>OR</p>
                            </div>
                          </Col>
                          <Col lg={5}>
                            <div className="distance-range">
                              <p>Select Distance Range (Kms)</p>
                              <div className="range-bar">
                                {/* <InputRange
                              draggableTrack
                              minValue={0}
                              maxValue={100}
                              value={{ min: 0, max: distanceValue.max }} // Lock the min value at 0
                              onChange={(value) =>
                                setDistanceValue({ min: 0, max: value.max })
                              } // Only update max
                              formatLabel={(value) => `${value} km`}
                            /> */}

                                <Slider
                                  value={distanceValue.max}
                                  onChange={(event, newValue) =>
                                    setDistanceValue({ min: 0, max: newValue })
                                  }
                                  valueLabelDisplay="on" // Ensures the value label is always visible
                                  valueLabelFormat={(value) =>
                                    `${value === 100 ? "Max" : `${value} km`}`
                                  } // Shows 'Max' at the endpoint
                                  min={0}
                                  max={100}
                                  marks={[
                                    { value: 0, label: "0 km" },
                                    { value: 100, label: " 100 Km" }, // Mark for the maximum point
                                  ]}
                                  aria-label="Distance"
                                  sx={{
                                    color: "red", // Change the color to red
                                    "& .MuiSlider-thumb": {
                                      backgroundColor: "white", // Change thumb color to white if desired
                                    },
                                    "& .MuiSlider-track": {
                                      backgroundColor: "red", // Change track color to red
                                    },
                                    "& .MuiSlider-rail": {
                                      backgroundColor: "rgba(255, 0, 0, 0.3)", // Optional: change the rail color
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="location-bg-second">
                    <Row>
                      <Col lg={5}>
                        <Form>
                          <Form.Group className="mb-3" controlId="priceInput">
                            <Form.Label>Desired Budget</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter your desired budget"
                              value={price}
                              min="0" // Prevents negative values
                              onChange={(e) =>
                                setPriceState(parseFloat(e.target.value) || "")
                              }
                            />
                          </Form.Group>
                          <div className="btn-holder">
                            <Button
                              className="btn-search"
                              onClick={handleSearchPrice}
                            >
                              SEARCH
                            </Button>
                            <Button
                              className="btn-clear"
                              onClick={handleClearPrice}
                            >
                              CLEAR
                            </Button>
                          </div>
                        </Form>
                      </Col>
                      <Col lg={2}>
                        <div className="circle-orr text-center">
                          <p>OR</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="distance-range">
                          <p>Select Budget Range</p>
                          <div className="range-bar">
                            <InputRange
                              draggableTrack
                              minValue={minValue} // Use fixed minValue
                              maxValue={maxValue}
                              value={{ min: minValue, max: priceValue.max }} // Lock the min value
                              onChange={(value) =>
                                setPriceValue({ min: minValue, max: value.max })
                              } // Only update max
                              formatLabel={(value) => `₹ ${value}`}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className="location-bg-third">
                    <Form>
                      <Row className="mb-3">
                        <p>Select Ratings</p>
                        {[1, 2, 3, 4, 5].map((ratingValue) => (
                          <Form.Group
                            as={Col}
                            lg={4}
                            key={ratingValue}
                            className="mb-3 d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center">
                              <Form.Check
                                type="checkbox"
                                name={`rating${ratingValue}`} // unique name for each checkbox
                                id={`ratingCheckbox${ratingValue}`}
                                value={ratingValue}
                                checked={rating.includes(ratingValue)} // check if the value is in the selected ratings array
                                onChange={(e) => {
                                  const value = parseInt(e.target.value); // Convert the value to an integer
                                  if (e.target.checked) {
                                    // Add the rating if checked
                                    setRatingState((prev) => [...prev, value]);
                                  } else {
                                    // Remove the rating if unchecked
                                    setRatingState((prev) =>
                                      prev.filter((item) => item !== value)
                                    );
                                  }
                                }}
                                className="me-2"
                              />
                              <FontAwesomeIcon
                                icon={faStar}
                                className="star-icon"
                              />
                              <span>{ratingValue}</span>
                            </div>
                          </Form.Group>
                        ))}
                      </Row>
                      <div className="btn-holder">
                        <Button
                          className="btn-search"
                          onClick={handleSearchRating}
                        >
                          SEARCH
                        </Button>
                        <Button
                          className="btn-clear"
                          onClick={handleClearRatings}
                        >
                          CLEAR
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

FilterModal.propTypes = {
  setLocation: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  setRating: PropTypes.func.isRequired,
};

export default FilterModal;
