import React, { useEffect, useState, useContext } from "react";
import "./home-motivated.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { MotivatedBy } from "../../../utils/apis/Home/Homeapi";
import parse from "html-react-parser";

const Motivated = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [data, setData] = useState([]);
  const { IMG_URL } = useContext(Context);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const getAllData = async () => {
    try {
      const res = await MotivatedBy();

      if (res?.success) {
        const fetchedData = res?.data || [];

        if (fetchedData.length === 0) {
          console.warn("No data fetched.");
          setData([]); // Clear state if no data is fetched
          return;
        }

        // Set all fetched data to the state directly, without limiting the length
        setData(fetchedData);
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <section className="home-motivated">
      <div className="container-md">
        <h1 className="title">We are motivated by</h1>
        <div className="slider-section">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={70}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1.5,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {data.map((val, index) => (
              <SwiperSlide key={index}>
                <div className="row justify-content-space-between">
                  <div className="col-lg-5 col-md-5 col-6 mx-auto">
                    <div className="imgSlider_sec">
                      <img
                        className="emp1"
                        src={IMG_URL + val?.image}
                        alt={val?.name}
                      />
                      <div className="img-bg1"></div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-10 mx-auto mb-5 colhide">
                    <img
                      className="symbol-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/homepage/motivated/symbol.png"
                      }
                      // src={
                      //   IMG_URL +
                      //   val?.image
                      // }
                      alt="symbol"
                    />
                    <p className="testimoni-cont">{parse(val?.description)}</p>
                    <p className="name">{val?.name}</p>
                    <p className="location">{val?.city?.name}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            {data.length > 2 && (
              <div className="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                >
                  <FontAwesomeIcon icon={faCaretLeft} />
                </div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                >
                  <FontAwesomeIcon icon={faCaretRight} />
                </div>
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Motivated;
