import React, { useState } from "react";
import "./StudioMediaCard.css";
import Card from "react-bootstrap/Card";
import {
  faBookBookmark,
  faBookmark,
  faCalendarCheck,
  faLocationDot,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StudioMediaCard = ({
  image,
  Title,
  LocationText,
  numberRate,
  isPreBooked,
  isBooked,
  averageRating,
}) => {
  const [isStarClicked, setIsStarClicked] = useState(false);

  const handleStarClick = () => {
    setIsStarClicked(!isStarClicked);
  };

  return (
    <>
      <Card className="media-card ">
        <div className="image-holder">
          <Card.Img className="media-img" src={image} variant="top" />
        </div>
        <Card.Body>
          <Card.Title>{Title}</Card.Title>
          <div className="location">
            <p>
              <FontAwesomeIcon icon={faLocationDot} className="me-2" />
              {LocationText}
            </p>
          </div>
          <div className="d-flex">
            <div className="details">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/studio/viewdetails.png"
                }
                className="list-search me-1"
                alt="View Details"
              />
              <span>View Details</span>
            </div>
            <div className="rate-list ms-auto">
              <p>
                {averageRating}
                <FontAwesomeIcon
                  icon={faStar}
                  className={`ms-1 star-icon ${isStarClicked ? "clicked" : ""}`}
                  onClick={handleStarClick}
                />
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default StudioMediaCard;
