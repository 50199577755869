import React, { useEffect, useRef, useState, useContext } from "react";
import "./Enquiry_form_modal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from "../Success_modal/Success_modal";
import { RegxExpression } from "../../../../utils/common";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";

import { EnquiryForm } from "../../../../utils/apis/form/form";
const Enquiry_form_modal = (props) => {
  const [phoneValue, setPhoneValue] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // const handleSubmit = () => {
  //     // Perform any necessary submission logic here

  //     // Close the current modal
  //     props.onHide();

  //     // Show the success modal
  //     setModalShow(true);
  // };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("subject", data?.subject);
    formData.append("message", data?.message);
    formData.append("agree_status", data?.agree_status);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);

    const res = await EnquiryForm(formData);
    if (res?.success) {
      reset();

      props.onHide();
      window.scrollTo(0, 0);
      setModalShow(true);
      // window.location.reload();
    } else {
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Enquiry_form_modal"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-md-8 col-9 pe-0">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="sec-1">
                  <Link to="/" onClick={props.onHide}>
                    <FontAwesomeIcon className="gomeicc" icon={faHouse} />
                  </Link>
                  <h6 className="enqh6">Enquiry</h6>

                  <Form.Group className="form-group">
                    <Form.Label>Enter Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                        // "is-valid": getValues("name"),
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.name.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Enter Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: RegxExpression.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                        // "is-valid": getValues("email"),
                      })}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Enter Mobile Number</Form.Label>
                    <Controller
                      name="contact_no"
                      control={control}
                      defaultValue="+91" // Set default prefix
                      rules={{
                        required: "Contact number is required",
                        minLength: {
                          value: 10,
                          message: "Number should be at least 10 characters",
                        },
                        validate: (value) =>
                          value.startsWith("+91")
                            ? true
                            : "Number must start with country code +91",
                      }}
                      render={({ field }) => (
                        <Form.Control
                          {...field}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            // "is-valid": getValues("contact_no"),
                          })}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value.length < 3) value = "+91"; // Prevent removing "+91"
                            field.onChange(value);
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            const pastedData = e.clipboardData
                              .getData("Text")
                              .replace(/[^0-9]/g, ""); // Only keep numeric values
                            if (pastedData) {
                              field.onChange("+91" + pastedData.slice(0, 10)); // Prefix "+91" and limit to 10 digits
                            }
                          }}
                          type="text"
                          placeholder="Enter Mobile Number"
                          onKeyDown={(event) => {
                            if (
                              !/^[0-9]$/.test(event.key) &&
                              event.key !== "Backspace" &&
                              event.key !== "ArrowLeft" &&
                              event.key !== "ArrowRight" &&
                              event.key !== "Delete"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={13} // "+91" plus 10 digits
                        />
                      )}
                    />
                    {errors?.contact_no && (
                      <sup className="text-danger">
                        {errors?.contact_no?.message}
                      </sup>
                    )}
                  </Form.Group>

                  {/* <Form.Group className="form-group">
                    <Form.Label>Enter Mobile Number</Form.Label>
                    <PhoneInput
                      className="mb-3"
                      country={"in"}
                      value={phoneValue}
                      placeholder="Enter Mobile Number"
                      onChange={setPhoneValue}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                  </Form.Group> */}

                  <Form.Group className="form-group">
                    {" "}
                    <Form.Label>Enter Message</Form.Label>
                    <Form.Control
                      type="text"
                      name="message"
                      placeholder="Enter Message"
                      {...register("message", {
                        required: "message required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.message,
                        // "is-valid": getValues("message"),
                      })}
                    />
                    {/* <Form.Control as="textarea" placeholder="Enter Message" /> */}
                    {/* <textarea class="form-control" placeholder="Enter Message" id="floatingTextarea"></textarea> */}
                  </Form.Group>

                  <Button className="submit-btn" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
            <div className="col-md-4 col-3 ps-0">
              <div className="sec-2">
                <img
                  className="ban-bg"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/homepage/inquiry/bg.png"
                  }
                  alt="background"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Success_modal
        maintext={"Enquiry Generated Successfully"}
        smalltext={""}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Enquiry_form_modal;
