import React, { useContext, useEffect, useState } from "react";
import "./Subscription.css";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import MonthlyCard from "./MonthlyCard/MonthlyCard";
import Subscription_banner from "./subscription-banner/Subscription_banner";
import {
  allSubscription,
  userSubscription,
} from "../../utils/apis/Home/Homeapi";
import { UserGet } from "../../utils/apis/myAccount/myAccount";
import { Context } from "../../utils/context";
const Subscription = () => {
  const [data, setData] = useState();
  const [subscriptionData, setSubscriptionData] = useState();

  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    setUserData,
    userdata,
  } = useContext(Context);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  const getAllData = async () => {
    const res = await allSubscription();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getuserSubscription = async () => {
    const res = await userSubscription();
    if (res?.success) {
      setSubscriptionData(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getUser();
    getAllData();
    getuserSubscription();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <section className="subscription">
        <Subscription_banner />

        <Container>
          <div className="background-card med-top">
            <img
              className="curve-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/about-us/banner/layer.png"
              }
            />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className="mx-auto "
                >
                  <Nav variant="pills" className="justify-content-center ">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Monthly</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Half Yearly</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Annually</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row className="justify-content-center">
                      <Col xxl={11} xl={11} lg={12}>
                        <Row>
                          {data?.map((value, index) => (
                            <Col key={index} lg={4} md={6} sm={6} className="mb-3">
                              <MonthlyCard
                                image={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/subscription/plan-image1.png"
                                }
                                content={value?.name}
                                subscription_id={value?.id}
                                type={"Month"}
                                allData={value}
                                premium_studios={value?.premium_studios}
                                description={value?.subscription_detail}
                                RupeesText={value?.price}
                                subscription_status={
                                  userdata?.subscription_status
                                }
                                activeplan={
                                  value?.subscription_detail?.subscription_id ==
                                    subscriptionData?.subscription_id &&
                                  subscriptionData?.type == "Month"
                                    ? "Active Plan"
                                    : ""
                                }
                              />
                            </Col>
                          ))}

                          {/* <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Niche"
                              RupeesText="₹ 99"
                            />
                          </Col>
                          <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Splendour"
                              RupeesText="₹ 99"
                            />
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>
                    ;
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Row className="justify-content-center">
                      <Col xxl={11} xl={11} lg={12}>
                        <Row>
                          {data?.map((value, index) => (
                            <Col key={index} lg={4} md={6} sm={6}>
                              <MonthlyCard
                                image={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/subscription/plan-image1.png"
                                }
                                type={"Year"}
                                subscription_id={value?.id}
                                content={value?.name}
                                allData={value}
                                description={value?.subscription_detail}
                                RupeesText={Number(value?.price) * 12}
                                subscription_status={
                                  userdata?.subscription_status
                                }
                                activeplan={
                                  value?.subscription_detail?.subscription_id ==
                                    subscriptionData?.subscription_id &&
                                  subscriptionData?.type == "Year"
                                    ? "Active Plan"
                                    : ""
                                }
                              />
                            </Col>
                          ))}
                          {/* <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Niche"
                              RupeesText="₹ 999"
                            />
                          </Col>
                          <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Splendour"
                              RupeesText="₹ 999"
                            />
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row className="justify-content-center">
                      <Col xxl={11} xl={11} lg={12}>
                        <Row>
                          {data?.map((value, index) => (
                            <Col key={index} lg={4} md={6} sm={6}>
                              <MonthlyCard
                                image={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/subscription/plan-image1.png"
                                }
                                content={value?.name}
                                subscription_id={value?.id}
                                type={"6 Month"}
                                description={value?.subscription_detail}
                                allData={value}
                                RupeesText={Number(value?.price) * 6}
                                subscription_status={
                                  userdata?.subscription_status
                                }
                                activeplan={
                                  value?.subscription_detail?.subscription_id ==
                                    subscriptionData?.subscription_id &&
                                  subscriptionData?.type == "6 Month"
                                    ? "Active Plan"
                                    : ""
                                }
                              />
                            </Col>
                          ))}
                          {/* <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Niche"
                              RupeesText="₹ 500"
                            />
                          </Col>
                          <Col lg={4} md={6} sm={6}>
                            <MonthlyCard
                              image={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription/plan-image1.png"
                              }
                              content="Splendour"
                              RupeesText="₹ 500"
                            />
                          </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Subscription;
