import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Account from "./components/Account/Account";
import Subscription from "./components/Subscription/Subscription";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./components/PrivacyPolicy/TermsAndCondition";
import Faq from "./components/Faq/Faq";
import MediaSpaces from "./components/Studio_header/MediaSpaces";
import About from "./components/about/About";
import Studio_inner from "./components/studio-inner/Studio_inner";
import Hourly_bookings from "./components/Bookings/hourly-bookings/Hourly_bookings";
import Daily_bookings from "./components/Bookings/daily-bookings/Daily_bookings";
import Contact from "./components/contact/Contact";
import Login from "./components/login/Login";
import Signup from "./components/login/Signup";
import Forgot_password from "./components/login/Forgot_password";
import Setnew_password from "./components/login/Setnew_password";
import AppContext, { Context } from "./utils/context";
import { UserGet } from "./utils/apis/myAccount/myAccount";
function App() {
  const { userdata, setUserData, signin } = useContext(Context);

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { pathname } = useLocation();

  return (
    <>
      {/* <ScrollToTop /> */}

      {/* <Header /> */}
      <AppContext>
        {pathname === "/login" ||
        pathname === "/sign-up/*" ||
        pathname === "/forgot-password" ||
        pathname === "/set-password/*" ? (
          ""
        ) : (
          <Header />
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/media-spaces/:id" element={<MediaSpaces />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/studio-inner/:id" element={<Studio_inner />} />
          <Route path="/hourly-bookings/:id" element={<Hourly_bookings />} />
          <Route path="/daily-bookings/:id" element={<Daily_bookings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up/:type" element={<Signup />} />
          <Route path="/forgot-password" element={<Forgot_password />} />
          <Route
            path="/set-password/:email_or_contact"
            element={<Setnew_password />}
          />
        </Routes>

        {pathname === "/login" ||
        pathname === "/sign-up/*" ||
        pathname === "/forgot-password" ||
        pathname === "/set-password/*" ? (
          ""
        ) : (
          <Footer />
        )}
      </AppContext>
      {/* <Footer /> */}
    </>
  );
}

export default App;
