import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const sendMobileOTP = async (contact_no) => {
//   try {
//     const res = await postData(`/without-login/otp/mobile-otp-send`, {
//       contact_no,
//     });

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const resendMobileOTP = async (contact_no) => {
//   try {
//     const res = await postData(`/without-login/verify-otp/mobile-otp-resend`, {
//       contact_no,
//     });

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const sendEmail_ContactNoOTP = async (email_or_contact) => {
  try {
    const res = await postData(
      `/without-login/otp/forget-password-email-otp-send`,
      { email_or_contact }
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyMobileOTP = async (contact_no, otp) => {
  try {
    const res = await postData(`/without-login/otp/mobile-verify-otp`, {
      contact_no,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmailOTP = async (email, otp) => {
  try {
    const res = await postData(`/without-login/otp/email-verify-otp`, {
      email,
      otp,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (email_or_contact, password) => {
  try {
    const res = await postData(`/without-login/otp/change-password`, {
      email_or_contact,
      password,
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const AppSetup = async () => {
  try {
    return await getData(`/without-login/home/app-setup`);
  } catch (error) {
    console.log(error);
  }
};

// export const resetPassWord = async (data) => {
//   try {
//     const res = await postData(`/outer-website/auth/reset-password`, data);

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const RegxExpression = {
  name: /[A-Z-a-z ]/,
  email: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
  phone: /[0-9]/,
  ifsc: "^[A-Z]{4}[0][dA-Z]{6}$",
  ifsc_code: /^[A-Z0-9]$/,
  micr: /^[0-9]{9}$/,
  bank_number: /^\d{0,14}$/,
  gst: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
};

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date
    .toLocaleDateString("en-US", { month: "short" })
    .toUpperCase();
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);

  return `${day}.${month}.${year}`;
};

export const latestPost = async (seller_id, cookArr) => {
  try {
    return await postData(
      `/outer-website/home/latest-viewed?seller_id=${seller_id}`,
      { product_id: cookArr }
    );
  } catch (error) {
    console.error(error);
  }
};
