import React, { useEffect, useRef, useState, useContext } from "react";
import "./Account.css";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import {
  faCalendarDays,
  faClock,
  faEyeSlash,
  faEye,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import {
  faListCheck,
  faMagnifyingGlass,
  faSquareCheck,
  faTableCellsLarge,
  faTicket,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import Account_banner from "./account-banner/Account_banner";
import Book_cancel_modal from "../common-section/Modals/book-cancel-modal/Book_cancel_modal";
import Historycard_view_modal from "../common-section/Modals/historycard-view-modal/Historycard_view_modal";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";
import Add_review_modal from "../common-section/Modals/add-review-modal/Add_review_modal";
import { Context } from "../../utils/context";
import {
  ProfileGet,
  EditProfile,
  getBooking,
  cancelBooking,
  getBookingHistory,
  getSubscriptionHistory,
  GetCustomerWallet,
  UserGet,
} from "../../utils/apis/myAccount/myAccount";
import {
  calculateRemainingHours,
  RegxExpression,
  stage,
} from "../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import parse from "html-react-parser";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import RescheduleModal from "./RescheduleModal/RescheduleModal";
import { userSubscriptionDetails } from "../../utils/apis/MediaSpace/MediaSpace";
import Withdraw_req_modal from "../common-section/Modals/withdraw-request-modal/Withdraw_req_modal";
import Pagination_Holder from "../common-section/Modals/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
import Withdraw_Recharge_modal from "../common-section/Modals/Withdraw_Recharge_modal/Withdraw_Recharge_modal";
// import { userRegister } from "../../utils/apis/login/Login";
import { useLocation } from "react-router-dom";
function Account() {
  const location = useLocation();
  const [currentBook, setCurrentBook] = useState({});
  const [perPage, setperPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [modalShow, setModalShow] = React.useState(false);
  const [reschedulemodalShow, setRescheduleModalShow] = useState(false);

  const isPastDate = (dateString) => {
    const givenDate = new Date(dateString);
    const today = new Date();

    // Ensure valid dates before comparison
    if (isNaN(givenDate.getTime())) {
      throw new Error("Invalid date string provided");
    }

    // Set both dates to midnight for a fair comparison
    givenDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return today < givenDate;
  };

  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [data, setData] = useState();
  const [wallet, setWallet] = useState();
  const [dataHistory, setDataHistory] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconNew, setIconNew] = useState(faEyeSlash);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "new_password") {
      setShowPasswordNew(!showPasswordNew);
      setIconNew(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    userdata,
    setUserData,
    isTokenValid,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);
    if (password === passwordAgain) {
      const res = await EditProfile(data);
      if (res?.success) {
        reset();
        setModalShow2(true);
        getAllData();
      } else {
        setShimmerLoader(false);

        if (res?.message) {
          setError("current_password", {
            type: "manual",
            message: res.message?.message,
          });
        }
        // Handle errors
        if (res?.message?.email) {
          setError("email", { type: "manual", message: res.message.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", {
            type: "manual",
            message: res.message.contact_no,
          });
        }
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [password, setPassword] = useState("");
  const [studioId, setStudioId] = useState(null);
  const [passwordAgain, setPasswordAgain] = useState("");
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await ProfileGet();
    if (res?.success) {
      reset(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [data_current, setData_current] = useState(1);
  const [data_totalPages, setdata_totalPages] = useState(1);
  const handleDataPageChange = (pageNumber) => {
    setData_current(pageNumber);
  };

  const getAllBookingData = async (selectedTimeframe = "DESC") => {
    const res = await getBooking(selectedTimeframe, data_current, perPage);
    if (res?.success) {
      setData(res?.data);
      setData_current(res?.data?.currentPage);
      setdata_totalPages(res?.data?.totalPages);
      setperPage(res?.data?.per_page);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllCustomerWallet = async () => {
    const res = await GetCustomerWallet(currentPage, perPage);
    if (res?.success) {
      setWallet(res?.data);
      setCurrentPage(res?.data?.currentPage);
      settotalPages(res?.data?.totalPages);
      setperPage(res?.data?.per_page);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [history_current, sethistory_current] = useState(1);
  const [history_totalPages, sethistory_totalPages] = useState(1);
  const handleHistoryPageChange = (pageNumber) => {
    sethistory_current(pageNumber);
  };

  const [studio_name, setStudionName] = useState("");
  const getAllBookingDataHistory = async (selectedTimeframe = "DESC") => {
    const res = await getBookingHistory(
      selectedTimeframe,
      studio_name,
      history_current,
      perPage
    );
    if (res?.success) {
      setDataHistory(res?.data);
      sethistory_current(res?.data?.currentPage);
      sethistory_totalPages(res?.data?.totalPages);
      setperPage(res?.data?.per_page);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const [cancelId, setcancelId] = useState("");
  const cancelResonse = async (id, start_date, start_time) => {
    setcancelId(id);
    setModalShow(true);
  };

  const [ADDId, setAddId] = useState("");
  const handelAddON = async (id) => {
    setAddId(id);
    setModalShow1(true);
  };

  const [subscriptions, setSubscriptions] = useState([]);

  const [subscriptions_current, setsubscriptions_current] = useState(1);
  const [subscriptions_totalPages, setsubscriptions_totalPages] = useState(1);
  const handlesubscriptionsPageChange = (pageNumber) => {
    setsubscriptions_current(pageNumber);
  };

  const getAllSubscriptionHistory = async () => {
    const res = await getSubscriptionHistory(subscriptions_current, perPage);
    if (res?.success) {
      setSubscriptions(res?.data);
      setsubscriptions_current(res?.data?.currentPage);
      setsubscriptions_totalPages(res?.data?.totalPages);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  const [modalShowWith, setModalShowWith] = React.useState(false);

  const [modalRechargeShow, setModalRechargeShow] = React.useState(false);

  useEffect(() => {
    getAllData();
    getAllBookingDataHistory();
    getAllBookingData();
    getAllSubscriptionHistory();
    getAllCustomerWallet();
  }, [
    currentPage,
    reschedulemodalShow,
    data_current,
    history_current,
    subscriptions_current,
    studio_name,
    modalRechargeShow,
    modalShowWith,
  ]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const formatTimeToAMPM = (time) => {
    // Split time by periods (it expects "HH.MM.SS" but handles missing parts)
    const parts = time.split(".");

    // Ensure hours and minutes exist, set defaults if not
    const hours = parts[0] || "00";
    const minutes = parts[1] || "00"; // Default to "00" if minutes are missing

    let hour = parseInt(hours, 10);
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12; // Convert to 12-hour format
      }
    } else if (hour === 0) {
      hour = 12; // Handle midnight case
    }

    return `${hour}:${String(minutes).padStart(2, "0")} ${period}`;
  };

  const handleRadioChange = (e) => {
    setSelectedTimeframe(e.target.value);
    getAllBookingData(e.target.value);
  };
  const handleRadioChangeHistory = (e) => {
    setSelectedTimeframe(e.target.value);
    getAllBookingDataHistory(e.target.value);
  };

  const handleRating = (id) => {
    setStudioId(id);
    setModalShow3(true);
  };

  const handleDownload = async (invoice) => {
    try {
      // const response = await getData(`/order/invoice-download/${id}`);

      const filePath = invoice;

      if (!filePath) {
        console.error("File path is undefined or null");
        return;
      }

      const url = `${IMG_URL}/${filePath}`;

      // Create an invisible link element and open the file in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in a new tab
      a.rel = "noopener noreferrer"; // Recommended for security reasons
      a.click();
    } catch (error) {
      console.error("Error opening file:", error);
    }
  };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(":");
    const period = +hours >= 12 ? "PM" : "AM";
    const formattedHours = +hours % 12 || 12; // Convert 24-hour to 12-hour format
    return `${formattedHours}:${minutes} ${period}`;
  };

  const [userSubscription, setSubscription] = useState();

  const getUserSubscription = async () => {
    const res = await userSubscriptionDetails();
    if (res?.success) {
      setSubscription(res.data);
    }
  };

  useEffect(() => {
    getUserSubscription();
  }, [reschedulemodalShow]);

  const today = new Date();

  const activeTab = location.state?.activeTab || "first";

  
  
  return (
    <>
      <section className="account_section">
        <Account_banner />

        <Container>
          <div className="account_main_div">
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
              <Row>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="mb-4">
                  <div className="acctab_div mb-4">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          {" "}
                          <FontAwesomeIcon
                            icon={faUser}
                            className="me-2"
                          />{" "}
                          Personal Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          {" "}
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            className="me-2"
                          />{" "}
                          Bookings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          onClick={() => getAllBookingDataHistory()}
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={faHistory}
                            className="me-2"
                          />{" "}
                          History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">
                          {" "}
                          <FontAwesomeIcon
                            icon={faReceipt}
                            className="me-2"
                          />{" "}
                          Subscription
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fiveth">
                          {" "}
                          <FontAwesomeIcon
                            icon={faWallet}
                            className="me-2"
                          />{" "}
                          Wallet Transaction History
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="acctab_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="second">
                        <p className="sorttext">Sort by</p>
                        <div className="shorttabss py-2">
                          <Form>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="All"
                                name="timeframe"
                                value=""
                                checked={selectedTimeframe === ""}
                                onChange={handleRadioChange}
                              />
                            </div>

                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Date (Asc)"
                                name="timeframe"
                                value="ASC"
                                checked={selectedTimeframe === "ASC"}
                                onChange={handleRadioChange}
                              />
                            </div>

                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Date (Desc)"
                                name="timeframe"
                                value="DESC"
                                checked={selectedTimeframe === "DESC"}
                                onChange={handleRadioChange}
                              />
                            </div>
                            {/* <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Hourly"
                                name="timeframe"
                                id="Hourly"
                                value="Hourly"
                                checked={selectedTimeframe === "Hourly"}
                                onChange={handleRadioChange}
                              />
                            </div> */}
                          </Form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p className="sorttext">Sort by</p>
                        <div className="shorttabss py-2">
                          <Form>
                            <div className="checcckboxx">
                              {/* <Form.Check
                                type="radio"
                                label="All"
                                name="timeframe"
                                id=""
                                value=""
                                checked={selectedTimeframe === ""}
                                onChange={handleRadioChangeHistory}
                              /> */}
                            </div>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Date (ASC)"
                                name="timeframe"
                                value="ASC"
                                checked={selectedTimeframe === "ASC"}
                                onChange={handleRadioChangeHistory}
                              />
                            </div>{" "}
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Date (DESC)"
                                name="timeframe"
                                value="DESC"
                                checked={selectedTimeframe === "DESC"}
                                onChange={handleRadioChangeHistory}
                              />
                            </div>
                          </Form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} className="mb-4">
                  <div className="acctabcont_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="tab1_div">
                          <Form className="row">
                            <Form.Group
                              className="mb-3 col-md-6 col-sm-6 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                First Name<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="First_Name"
                                placeholder="Enter First Name"
                                {...register("first_name", {
                                  required: "First name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.first_name,
                                  // "is-valid": getValues("first_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-md-6 col-sm-6 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Last Name<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                placeholder="Enter Last Name"
                                {...register("last_name", {
                                  required: "Last name is required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.last_name,
                                  // "is-valid": getValues("last_name"),
                                })}
                                onKeyDown={(event) => {
                                  const regex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace" &&
                                    event.key !== "Tab"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Display Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="display_name"
                                placeholder={`${getValues(
                                  "first_name"
                                )} ${getValues("last_name")}`}
                                {...register("display_name", {
                                  // required: "Display name is required",
                                })}
                                defaultValue={getValues("first_name")}
                                className={classNames("", {
                                  "is-invalid": errors?.display_name,
                                })}
                                readOnly
                              />
                              <p className="displa-instru">
                                This will be how your name will be displayed in
                                the account section and in reviews.
                              </p>
                            </Form.Group>

                            <div className="mb-3">
                              <Form.Label>Contact</Form.Label>

                              <Controller
                                name="contact_no"
                                control={control}
                                defaultValue="+91" // Default value for the field
                                rules={{
                                  required: "Contact number is required",
                                  minLength: {
                                    value: 13, // +91 + 10 digits
                                    message:
                                      "Number should be at least +91 + 10 Number",
                                  },
                                  validate: (value) =>
                                    value.startsWith("+91")
                                      ? true
                                      : "Number must start with country code +91",
                                }}
                                render={({ field }) => (
                                  <Form.Control
                                    {...field}
                                    className={classNames("", {
                                      "is-invalid": errors?.contact_no,
                                    })}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (
                                        value.length >= 3 ||
                                        value === "+91"
                                      ) {
                                        field.onChange(value); // Allow changes only after the prefix
                                      }
                                    }}
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    onKeyDown={(event) => {
                                      // Ensure only numbers or valid backspace/arrow keys are pressed
                                      if (
                                        !/^[0-9]$/.test(event.key) &&
                                        ![
                                          "Backspace",
                                          "ArrowLeft",
                                          "ArrowRight",
                                        ].includes(event.key)
                                      ) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxLength={13} // Adjusted for "+91" plus 10 digits
                                    readOnly
                                  />
                                )}
                              />
                              {errors?.contact_no && (
                                <sup className="text-danger">
                                  {errors?.contact_no?.message}
                                </sup>
                              )}
                            </div>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: RegxExpression.email,
                                    message: "Invalid email address",
                                  },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                  // "is-valid": getValues("email"),
                                })}
                                readOnly
                              />
                              {errors?.email && (
                                <sup className="text-danger">
                                  {errors?.email?.message}
                                </sup>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Current Password
                                <span className="smalllabel">
                                  {" "}
                                  (leave blank to leave unchanged)
                                </span>
                              </Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  name="current_password"
                                  {...register("current_password", {
                                    // required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must be at least 8 characters long",
                                    },
                                    pattern: {
                                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                      message:
                                        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    },
                                  })}
                                />
                                {errors?.current_password && (
                                  <sup className="text-danger">
                                    {errors?.current_password?.message}
                                  </sup>
                                )}
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconOne}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility("password")
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                New Password{" "}
                                <span className="smalllabel">
                                  {" "}
                                  (leave blank to leave unchanged)
                                </span>
                              </Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPasswordNew ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  name="new_password"
                                  {...register("new_password", {
                                    // required: "new_password is required",
                                    // minLength: {
                                    //     value: 8,
                                    //     message:
                                    //         "Password must be at least 8 characters long",
                                    // },
                                    // pattern: {
                                    //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    //     message:
                                    //         "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    // },
                                  })}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconNew}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility("new_password")
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Confirm Password</Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPasswordOne ? "text" : "password"}
                                  placeholder="Re-Enter Password*"
                                  name="re_password"
                                  // {...register("re_password", {
                                  //     required: "Re-Password is required",
                                  // })}
                                  onChange={(e) =>
                                    setPasswordAgain(e.target.value)
                                  }
                                />
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconTwo}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "reenterPassword"
                                      )
                                    }
                                  />
                                </div>
                                {/* {errors?.re_password && (
                                  <sup className="text-danger">
                                    {errors?.re_password?.message}
                                  </sup>
                                )} */}
                              </div>
                              <div className="password-validation-text-holder">
                                {password && (
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                    ]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    onChange={(isValid) => {}}
                                    style={{
                                      fontSize: "10px",
                                      padding: "4px",
                                      margin: "0",
                                    }}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Form>
                          <div>
                            {/* <button className='btnnsub mt-3' type='submit' onClick={() => setModalShow2(true)} >SAVE CHANGES</button> */}
                            <button
                              className="btnnsub mt-3"
                              onClick={handleSubmit(onSubmit)}
                            >
                              SAVE CHANGES
                            </button>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="second">
                        <div>
                          {data?.data?.length > 0 ? (
                            data.data?.map((val, index) => (
                              <div className="bookiingtab_div">
                                <Row>
                                  <Col
                                    xxl={3}
                                    xl={3}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    className="pe-sm-0"
                                  >
                                    <div className="imgsidemain">
                                      <div className=" imggsssdiv">
                                        <img
                                          className="imgg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/logo/logo.png"
                                          }
                                          alt="Logo"
                                        />
                                      </div>
                                      <div className="imggtxxxt pt-2">
                                        <p className="bookprice mb-0">
                                          Booking: ₹ {Math.round(val?.amount)}/-
                                        </p>
                                        <p className="adrtext">
                                          (Starting from: ₹{" "}
                                          {val?.studio?.hourly_basis_charges}
                                          /Hr)
                                        </p>
                                        {val?.studio?.cancellation_status && (val?.stage == stage.Accepted ||
                                        val?.stage == stage.Requested ||
                                        val?.stage == stage.Rescheduled) ? (
                                          <p
                                            className="adrtext mb-0"
                                            onClick={() => {
                                              setCurrentBook(val);
                                              cancelResonse(
                                                val?.id,
                                                val?.start_date,
                                                val?.start_time
                                              );
                                            }}
                                            type="button"
                                          >
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faCalendarDays}
                                            />
                                            CANCEL
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl={9}
                                    xl={9}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    className="ps-sm-0"
                                  >
                                    <div className="booktext_div">
                                      <div className="card-heading-holder">
                                        <div>
                                          <p className="stdtext mb-1">
                                            {val?.studio?.name}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="timee time2 mb-1">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faTicket}
                                              className="me-1"
                                            />{" "}
                                            Status: {val?.stage}
                                          </p>
                                        </div>{" "}
                                        <div>
                                          <p className="timee time2 mb-1">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faUser}
                                              className="me-1"
                                            />{" "}
                                            Visiting Crew Member :{" "}
                                            {val?.capacity}
                                          </p>
                                        </div>
                                      </div>

                                      <p className="adrtext mb-1">
                                        {val?.studio?.address}
                                      </p>

                                      <br></br>

                                      {/* <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faTicket}
                                          className="me-1"
                                        />{" "}
                                        Booking: {val?.type}
                                      </p> */}

                                      <div className="d-flex justify-content-between flex-wrap">
                                        <p className="timee">
                                          <FontAwesomeIcon
                                            icon={faCalendarDays}
                                          />{" "}
                                          {val?.date ? (
                                            <>
                                              <p className="datespan me-1 p-1">
                                                {formatDate(val.date)}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p className="datespan me-1 p-1">
                                                {formatDate(val?.start_date)}
                                              </p>{" "}
                                              to
                                              <p className="datespan ms-1 p-1">
                                                {formatDate(val?.end_date)}
                                              </p>
                                            </>
                                          )}
                                        </p>

                                        {/* <p className='timee '><FontAwesomeIcon icon={faCalendarDays} /> <span className='datespan me-1 p-1'>{val?.start_date}</span> to  <span className='datespan ms-1 p-1'> {val?.end_date} </span></p> */}
                                        <p className="timee">
                                          <FontAwesomeIcon icon={faClock} />{" "}
                                          <p className="datespan me-1 p-1">
                                            {formatTimeTo12Hour(
                                              val?.start_time
                                            )}{" "}
                                          </p>{" "}
                                          to{" "}
                                          <p className="datespan ms-1 p-1">
                                            {formatTimeTo12Hour(val?.end_time)}
                                          </p>
                                        </p>
                                      </div>

                                      <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faTableCellsLarge}
                                          className="me-1"
                                        />{" "}
                                        Add Ons:
                                      </p>
                                      {val?.booking_add_ons &&
                                      val.booking_add_ons.length > 0 ? (
                                        val.booking_add_ons.map((val1) => (
                                          <p
                                            className="adrtext mb-1 ps-3 ms-1"
                                            key={val1.id}
                                          >
                                            {val1?.studio_add_on?.add_on?.name}{" "}
                                            +₹
                                            {val1?.studio_add_on?.rate}/hr
                                          </p>
                                        ))
                                      ) : (
                                        <p
                                          className="adrtext mb-1 ps-3 ms-1"
                                          style={{ color: "red" }}
                                        >
                                          No Add-on Added
                                        </p>
                                      )}

                                      {/* <p className="adrtext mb-1 ps-3 ms-1">
                                        Crew
                                      </p> */}
                                      {/* <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faListCheck}
                                          className="me-1"
                                        />{" "}
                                        Description:
                                      </p> */}
                                      <div className="d-flex justify-content-between flex-wrap mt-3">
                                        {/* {(val?.stage === stage.Accepted ||
                                          val?.stage === stage.Requested ||
                                          val?.stage === stage.Rescheduled) &&
                                        isPastDate(val.start_date) &&
                                        userSubscription &&
                                        userSubscription?.rechedule_booking_count >
                                          0 ? (
                                          <button
                                            className="reshcedule-btnnn"
                                            onClick={() =>
                                              setRescheduleModalShow(val?.id)
                                            }
                                          >
                                            Reschedule
                                          </button>
                                        ) : (
                                          ""
                                        )} */}

                                        {val?.studio?.reschedule_status && (val?.stage === stage.Accepted ||
                                        val?.stage === stage.Requested ||
                                        val?.stage === stage.Rescheduled )? (
                                          <button
                                            className="reshcedule-btnnn"
                                            onClick={() => {
                                              setCurrentBook(val);
                                              setRescheduleModalShow(val?.id);
                                            }}
                                          >
                                            Reschedule
                                          </button>
                                        ) : null}

                                        <p className="adrtext mb-0 ps-3 ms-1">
                                          {val?.basic_information?.description}
                                        </p>
                                        {val?.stage === "Accepted" && (
                                          <p
                                            className="timee time2  text-decoration-underline mb-0"
                                            onClick={() =>
                                              handleDownload(val?.invoice)
                                            }
                                          >
                                            View Invoice
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center"
                              style={{
                                fontWeight: "bold",
                                padding: "20px",
                                color: "white",
                              }}
                            >
                              No Data Available
                            </div>
                          )}

                          {data?.data?.length > 0 && (
                            <Pagination_Holder
                              onPageChange={data_current}
                              totalPages={data_totalPages}
                              handlePageChange={handleDataPageChange}
                            />
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="third">
                        <div className="tab2div">
                          <Form.Group
                            className="mb-5 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="eyediv">
                              <Form.Control
                                type="text"
                                placeholder="Studio Name"
                                // value={searchTerm}
                                onChange={(e) => {
                                  handleHistoryPageChange(1);
                                  setStudionName(e.target.value);
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="eyeicn2"
                              />
                            </div>
                          </Form.Group>

                          {dataHistory?.data?.length > 0 ? (
                            dataHistory?.data?.map((val, index) => (
                              <div className="tabbcard_div">
                                <div className="crdd1_div">
                                  <div className="imgrowdivv">
                                    <div className=" imggdiv">
                                      <img
                                        className="imgg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/logo/logo.png"
                                        }
                                        alt="Logo"
                                      />
                                    </div>
                                    <div className="bookd_div">
                                      <p className="booktext">
                                        Booked on:{" "}
                                        {val?.createdAt
                                          ? formatDate(val.createdAt)
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" texttdiv">
                                    <p className="stdtext mb-1">
                                      {val?.studio?.name}
                                    </p>
                                    <p className="adrtext mb-1">
                                      {val?.studio?.address}
                                    </p>{" "}
                                    <p className="timee time2 mb-1">
                                      Visiting Crew :- {val?.capacity}
                                    </p>
                                    <p className="timee time2 mb-1">
                                      Booking: {val?.type} (₹ {val?.amount}
                                      /-)
                                    </p>
                                    <div className="d-flex justify-content-between time_container">
                                      <p className="timee mb-1">
                                        <FontAwesomeIcon
                                          icon={faCalendarDays}
                                        />
                                        {val?.date ? (
                                          val.date
                                        ) : (
                                          <>
                                            <p className="timefrom">
                                              {formatDate(val?.start_date)}
                                            </p>
                                            to
                                            <p className="timeto">
                                              {formatDate(val?.end_date)}
                                            </p>
                                          </>
                                        )}
                                      </p>
                                      <p className="timee mb-1">
                                        <FontAwesomeIcon icon={faClock} />{" "}
                                        {formatTimeToAMPM(val.start_time)} to{" "}
                                        {formatTimeToAMPM(val.end_time)}
                                      </p>
                                    </div>
                                    {val?.review == false ? (
                                      <div className="d-flex justify-content-between text_footer">
                                        {/* {val?.studio?.studio_reviews?.length <
                                      0 ? ( */}
                                        <p
                                          className="timee time2 mb-0"
                                          onClick={() =>
                                            handleRating(val?.studio?.id)
                                          }
                                        >
                                          Add Ratings & Review
                                        </p>
                                        {/* ) : (
                                        <></>
                                      )} */}

                                        <p
                                          className="timee time2  text-decoration-underline mb-0"
                                          onClick={() => handelAddON(val?.id)}
                                        >
                                          View More
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={
                                      val?.stage == stage.Accepted
                                        ? "complettext"
                                        : val?.stage == stage.Rejected
                                        ? "rejectedtext"
                                        : val?.stage == stage.Cancelled
                                        ? "cancledtext"
                                        : "requestedtext"
                                    }
                                  >
                                    <p>{val?.stage}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center"
                              style={{
                                fontWeight: "bold",
                                padding: "20px",
                                color: "white",
                              }}
                            >
                              No Data Available
                            </div>
                          )}

                          {dataHistory?.data?.length > 0 && (
                            <Pagination_Holder
                              onPageChange={history_current}
                              totalPages={history_totalPages}
                              handlePageChange={handleHistoryPageChange}
                            />
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourth">
                        <div>
                          {subscriptions && subscriptions?.data?.length > 0 ? (
                            subscriptions?.data?.map((subscription, index) => (
                              <div className="bookiingtab_div" key={index}>
                                <Row>
                                  <Col
                                    xxl={3}
                                    xl={3}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    className="pe-0"
                                  >
                                    <div className="imgsidemain forrdivv">
                                      <div className="imggsssdiv">
                                        <img
                                          className="imgg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/logo/logo.png"
                                          }
                                          alt="Logo"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl={9}
                                    xl={9}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                    className="ps-0"
                                  >
                                    <div className="booktext_div subscrtabdiv">
                                      <p className="stdtext mb-1">
                                        {subscription?.subscription?.name} (₹
                                        {subscription?.price})
                                      </p>
                                      <p className="adrtext mb-1">
                                        <FontAwesomeIcon
                                          icon={faSquareCheck}
                                          className="squrecheckiccn me-1"
                                        />
                                        {`${subscription?.subscription?.subscription_detail?.base_price_percent}% off on base price on every ${subscription?.subscription?.subscription_detail?.base_price_booking_no}th booking `}
                                      </p>
                                      <p className="adrtext mb-1">
                                        <FontAwesomeIcon
                                          icon={faSquareCheck}
                                          className="squrecheckiccn me-1"
                                        />
                                        {`${subscription?.subscription?.subscription_detail?.free_booking_hours}hr free booking on every ${subscription?.subscription?.subscription_detail?.free_booking_hours_booking_no}th booking on selected studios`}
                                      </p>
                                      <p className="adrtext mb-1">
                                        <FontAwesomeIcon
                                          icon={faSquareCheck}
                                          className="squrecheckiccn me-1"
                                        />
                                        {`re-schedule booking ${subscription?.subscription?.subscription_detail?.rechedule_month} Times a Month`}
                                      </p>
                                      <p className="adrtext mb-1">
                                        <FontAwesomeIcon
                                          icon={faSquareCheck}
                                          className="squrecheckiccn me-1"
                                        />
                                        {`access to upto ${subscription?.subscription?.subscription_detail?.access_premium_studio_no} premium studios`}
                                      </p>

                                      <div className="update_div">
                                        <p>
                                          {subscription?.expiry_date ===
                                          "Expired"
                                            ? "Expired"
                                            : "Active "}
                                        </p>
                                      </div>
                                      <div className="monthly_div">
                                        <h3 className="monthly_p">
                                          {subscription?.type}
                                        </h3>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <p className="validuptoo">
                                  Valid Till {subscription?.expiry_date}
                                </p>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center"
                              style={{
                                fontWeight: "bold",
                                padding: "20px",
                                color: "white",
                              }}
                            >
                              <p>No subscription found</p>
                            </div>
                          )}

                          {subscriptions?.data?.length > 0 && (
                            <Pagination_Holder
                              onPageChange={subscriptions_current}
                              totalPages={subscriptions_totalPages}
                              handlePageChange={handlesubscriptionsPageChange}
                            />
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fiveth">
                        <div className="wallettablediv">
                          <div className="row">
                            <div className="col-md-4 col-12 mb-3">
                              <p className="wallrs">
                                Wallet Amount :{" "}
                                <span>₹ {userdata?.amount}/-</span>
                              </p>
                            </div>
                            {userdata?.amount > 0 && (
                              <>
                                <div className="col-md-4 col-sm-6 col-12 text-center mb-3">
                                  <button
                                    className="btn withdrbtn"
                                    type="button"
                                    onClick={() => setModalShowWith(true)}
                                  >
                                    Withdrawal Request
                                  </button>
                                </div>
                              </>
                            )}
                            <div className="col-md-4 col-sm-6 col-12 text-center mb-3">
                              <button
                                className="btn withdrbtn"
                                type="button"
                                onClick={() => setModalRechargeShow(true)}
                              >
                                Wallet Recharge
                              </button>
                            </div>
                          </div>

                          <div className="table-responsive mt-3">
                            <table className="table table-bordered ">
                              <thead>
                                <tr>
                                  <th>No.</th>
                                  <th>Booking Id</th>
                                  <th>Booking Date</th>
                                  <th>Studio Id</th>
                                  <th>Studio Name</th>
                                  <th>Studio Address</th>
                                  <th>Booking Duration</th>
                                  <th>Amount</th>
                                  <th>Transaction Type</th>
                                  <th>My Booking Status</th>
                                  <th>Payment Mode</th>
                                  <th>Withdrawal Request Status</th>
                                </tr>
                              </thead>

                              <tbody>
                                {wallet?.data?.length > 0 ? (
                                  wallet?.data?.map((d, index) => {
                                    const paginatedIndex =
                                      (currentPage - 1) * perPage + index + 1;
                                    return (
                                      <tr key={index}>
                                        <td>{paginatedIndex}.</td>
                                        <td>{d.book_studio?.id}</td>
                                        <td>
                                          {d.book_studio?.createdAt
                                            ? new Date(
                                                d.book_studio?.createdAt
                                              ).toLocaleDateString("en-GB")
                                            : "-"}
                                        </td>
                                        <td>
                                          {d.book_studio?.studio_id || "-"}
                                        </td>
                                        <td>
                                          {d.book_studio?.studio?.name || "-"}
                                        </td>
                                        <td>
                                          {d.book_studio?.studio?.address ||
                                            "-"}
                                        </td>
                                        <td>
                                          {d?.book_studio?.start_time &&
                                          d?.book_studio?.end_time
                                            ? (() => {
                                                const referenceDate =
                                                  "1970-01-01"; // Using a reference date to create valid Date objects

                                                const startTime = new Date(
                                                  `${referenceDate}T${d.book_studio.start_time}Z`
                                                );
                                                const endTime = new Date(
                                                  `${referenceDate}T${d.book_studio.end_time}Z`
                                                );

                                                const diffInMs =
                                                  endTime - startTime;

                                                const hours = Math.floor(
                                                  diffInMs / (1000 * 60 * 60)
                                                ); // hours
                                                const minutes = Math.floor(
                                                  (diffInMs %
                                                    (1000 * 60 * 60)) /
                                                    (1000 * 60)
                                                ); // minutes

                                                return `${hours}h ${minutes}m`;
                                              })()
                                            : "-"}
                                        </td>{" "}
                                        <td>₹{d?.amount}</td>
                                        <td>{d.transaction_type || "-"}</td>
                                        <td>{d.book_studio?.stage || "-"}</td>
                                        <td>
                                          {d.book_studio?.payment_type || "-"}
                                        </td>{" "}
                                        <td>
                                          {d.withdraw_status?.name || "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      style={{ textAlign: "center" }}
                                    >
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {wallet?.data?.length > 0 && (
                            <Pagination_Holder
                              onPageChange={currentPage}
                              totalPages={totalPages}
                              handlePageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>

        <Book_cancel_modal
          getAllBookingData={getAllBookingData}
          currentBook={currentBook}
          show={modalShow}
          ID={cancelId}
          onHide={() => setModalShow(false)}
        />

        <Historycard_view_modal
          ID={ADDId}
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        />

        <Success_modal
          maintext={"Changes Saved Successfully"}
          smalltext={""}
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        />

        <Add_review_modal
          getAllBookingDataHistory={getAllBookingDataHistory}
          studioId={studioId}
          show={modalShow3}
          onHide={() => setModalShow3(false)}
        />

        <RescheduleModal
          show={reschedulemodalShow}
          currentBook={currentBook}
          data={currentBook.studio}
          onHide={() => setRescheduleModalShow(false)}
          setModalShow2={setModalShow2}
          getAllBookingData={getAllBookingData}
          getUserSubscription={getUserSubscription}
        />
      </section>

      <Withdraw_req_modal
        show={modalShowWith}
        onHide={() => setModalShowWith(false)}
      />

      <Withdraw_Recharge_modal
        show={modalRechargeShow}
        onHide={() => setModalRechargeShow(false)}
      />
    </>
  );
}

export default Account;
