import { useState, useEffect, useContext } from "react";
import "../../bookings-form.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import TimePicker from "react-time-picker";

import { TimePicker } from "rsuite";
import "rsuite/dist/rsuite.css";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-phone-input-2/lib/style.css";
import Accordion from "react-bootstrap/Accordion";
import Success_modal from "../../../common-section/Modals/Success_modal/Success_modal";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../../../utils/common";

import classNames from "classnames";
import useRazorpay from "react-razorpay";
import { getAdditionalSupport } from "../../../../utils/apis/master/Master";
import {
  postBooking,
  postCalculation,
} from "../../../../utils/apis/booking/booking";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { UserGet } from "../../../../utils/apis/myAccount/myAccount";
import {
  perticularUserBookings,
  userSubscriptionDetails,
} from "../../../../utils/apis/MediaSpace/MediaSpace";
import Wallet_Payment_modal from "../../../common-section/Modals/Wallet_Payment_modal/Wallet_Payment_modal";
import { ColorRing } from "react-loader-spinner";

import { format } from "date-fns";
const Daily_form = ({ data }) => {
  const { IMG_URL, appSetup, userdata, setUserData, getUser } = useContext(
    Context
  );

  const [userSubscription, setSubscription] = useState();
  const [modalWalletShow, setModalWalleteShow] = useState();

  const [userBooking, setUserBookings] = useState();

  const getUserSubscription = async () => {
    const res = await userSubscriptionDetails();
    if (res?.success) {
      setSubscription(res.data);
    }
  };

  const getUserBooking = async () => {
    const res = await perticularUserBookings();
    if (res?.success) {
      setUserBookings(res.data);
    }
  };

  console.log(data,"data data data data");
  
  useEffect(() => {
    getUser();
    getUserBooking();
    getUserSubscription();
  }, []);

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [value, onChange] = useState("10:00");

  const [phoneValue, setPhoneValue] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      calculation_status: false,
    },
  });

  const watchStartDate = watch("start_date");

  const watchEndDate = data?.open_24_hours ? watchStartDate : watch("end_date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");

  const startTime = watch("start_time");
  const date = new Date(startTime);

  const formattedStartTime = date.toLocaleString("en-IN", {
    hour: "2-digit", // Hour in 24-hour format (e.g., 20)
    minute: "2-digit", // Minute (e.g., 00)
    second: "2-digit", // Second (e.g., 00)
    hour12: false, // Ensures 24-hour format is used
  });

  const endTime = new Date(watchEndTime);

  const formattedEndTime = endTime.toLocaleTimeString("en-IN", {
    hour: "2-digit", // Hour in 24-hour format (e.g., 20)
    minute: "2-digit", // Minute (e.g., 00)
    second: "2-digit", // Second (e.g., 00)
    hour12: false,
  });

  const [studioId, seStudioId] = useState();

  const [useDays, setUseDays] = useState();

  const [useRoundedHours, setRoundedHours] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (watchStartDate) {
      setStartDate(new Date(watchStartDate));
    }
  }, [watchStartDate]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [chargesError, setChargesError] = useState(false);

  useEffect(() => {
    seStudioId(data?.id);
    if (watchStartDate && watchEndDate && watchStartTime && watchEndTime) {
      const startDate = new Date(watchStartDate);

      const endDate = new Date(watchEndDate);

      const startTime = new Date(`1970-01-01T${watchStartTime}:00`);
      const formattedStartTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const endTime = new Date(`1970-01-01T${watchEndTime}:00`);
      const formattedEndTime = endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24) + 1);
      setUseDays(days);

      setValue("days", days);
      function parseTimeToMilliseconds(timeStr) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return (hours * 60 + minutes) * 60 * 1000; // Convert to milliseconds
      }

      const formattedStartTimenow = formattedStartTime;
      const formattedEndTimenow = formattedEndTime;

      const startTimeMs = parseTimeToMilliseconds(formattedStartTimenow);
      const endTimeMs = parseTimeToMilliseconds(formattedEndTimenow);

      const hours = Math.max((endTimeMs - startTimeMs) / (1000 * 60 * 60), 0);
      const roundedHours = Math.ceil(hours);
      setRoundedHours(roundedHours);

      // Calculate charges
      // const dayCharges = days * data?.full_day_charges;
      // const hourCharges = TimeDifference * data?.hourly_basis_charges;

      // setTotalCharge(dayCharges + hourCharges);
    }
  }, [watchStartDate, watchEndDate, watchStartTime, watchEndTime]);

  const selectedAddOns = watch("add_on_id");

  const [checkedAddOns, setCheckedAddOns] = useState([]);
  const [totalAddOnCharges, setTotalAddOnCharges] = useState(0);

  const handleCheckboxChange = (id, checked, rate) => {
    const currentAddOns = selectedAddOns || [];

    if (checked) {
      const newTotalCharge = totalCharge + Number(rate);
      setValue("add_on_id", [...currentAddOns, id]);
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal + Number(rate));
    } else {
      const newTotalCharge = totalCharge - Number(rate);
      setValue(
        "add_on_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal - Number(rate));
    }
  };

  const selectedsupport = watch("additional_support_id");

  const handleCheckboxChangeSupport = (id, checked) => {
    const currentAddOns = selectedsupport || [];
    if (checked) {
      setValue("additional_support_id", [...currentAddOns, id]);
    } else {
      setValue(
        "additional_support_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
    }
  };

  const [dataSupport, setDataSupport] = useState();
  const getAllData = async () => {
    const res = await getAdditionalSupport();
    if (res?.success) {
      setDataSupport(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();

  const [loading, setLoading] = useState();

  const [paymentType, setPaymentType] = useState();

  const onSubmit = (datafinal) => {
    if (datafinal?.calculation_status) {
      clearErrors("end_time");
      // Check the payment type and handle accordingly
      if (paymentType === "wallet") {
        finalFunction(datafinal);
      } else {
        handleRazorpay(datafinal);
      }
    } else {
      setError("calculation_status", {
        type: "manual",
        message: "Please press Calculate",
      });
    }
  };

  const handleRazorpay = async (data) => {
    let amountToPay = Math.round(details?.total);

    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(amountToPay * 100),
      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(data, res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const finalFunction = async (data, payment_id = null) => {
    setLoading(true);

    function incrementDate(date, hours = 0) {
      const dateObj = new Date(date);
      dateObj.setHours(dateObj.getHours() + hours);

      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      };

      return dateObj.toLocaleString("en-IN", options);
    }

    const datafinal = {
      studio_id: studioId,
      type: "Daily",
      start_time: formattedStartTime,
      capacity: data?.capacity,
      hours: data?.hours,
      end_time: formattedEndTime,
      start_date: incrementDate(data?.start_date),
      end_date: incrementDate(data?.end_date),
      name: data?.name,
      amount: Math.round(details?.total),
      payment_id: payment_id || "wallet",
      contact_no: data?.contact_no,
      days: data?.days,
      add_on_id: data?.add_on_id,
      additional_support_id: data?.additional_support_id,
      support_description: data?.support_description,
      platform_charges: details?.p_charge_p,
      gst_charge: details?.gstchargestoshow,
      afteroffer: details?.afteroffer,
      add_on_price: details?.add_one_price,
      free_hours: details?.free_h_p_discount,
      base_price_discount_rupees: details?.basepricediscountrupees,
      base_price_discount: details?.base_price_discount,
      base_price: details?.base_price,
      payment_type: paymentType,
    };

    if (userdata?.subscription_status == 1) {
      datafinal.after_subscription_date = "Subscribe Payment";
    }

    if (data?.email) {
      datafinal.email = data?.email;
    }

    if (data?.description) {
      datafinal.description = data?.description;
    }

    const res = await postBooking(datafinal);

    if (res?.success) {
      reset();
      getUser();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        setLoading(false);
        navigate("/");
      }, 3000);
    } else {
      setError("message", res?.message);
    }
  };

  const errorStyle = {
    color: "red",
    fontSize: "14px",
    margin: "10px 0",
  };

  const [details, setDetails] = useState({
    days: 0,
    base_price: 0,
    charges: {
      full_h_p: 0,
      full_h_c: 0,
      half_h_p: 0,
      half_h_c: 0,
      min_h_p: 0,
      min_h_c: 0,
    },
    platform_charge: 0,
    gst: 0,
    start_time: "00:00:00",
    end_time: "00:00:00",
    hours: 0,
    total: 0,
    add_one_price: 0,
    free_h_p_discount: 0,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonOfPayment, setButtonOfPayment] = useState(false);

  const [isCalculationDone, setIsCalculationDone] = useState(false);
  const [calculationError, setCalculationError] = useState(false);

  const onSubmitCalculation = async () => {
    console.log(data,"data data");
    
    setIsButtonDisabled(true);

    function incrementDate(date, hours = 0) {
      const dateObj = new Date(date);
      dateObj.setHours(dateObj.getHours() + hours);

      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      };

      return dateObj.toLocaleString("en-IN", options);
    }

    const startDate = getValues("start_date");
    const formattedStartDate = startDate
      ? new Date(startDate).toLocaleDateString("en-CA") // Formats as YYYY-MM-DD
      : null;

    const endDate = getValues("end_date");
    const formattedEndDate = endDate
      ? new Date(endDate).toLocaleDateString("en-CA") // Formats as YYYY-MM-DD
      : null;

    const f_data = {
      days: data?.open_24_hours == 1 ? 1 : getValues("days"),
      hours: getValues("hours"),
      studio_id: data?.id,
      r_s_time: formattedStartTime,
      r_e_time: formattedEndTime,
      add_one_price: totalCharge,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      // days: days,
      capacity: getValues("capacity"),
    };

    try {
      const res = await postCalculation(f_data);

      if (res?.success) {
        setButtonOfPayment(true);
        setCalculationError(false);
        setDetails(res.data);
        setValue("end_time", getValues("end_time"));
        setValue("calculation_status", res?.success);
        setIsCalculationDone(true);
      } else {
        setCalculationError(res?.message);
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsButtonDisabled(false);
    }
  };

  // Function to convert time to AM/PM format
  const convertToAmPm = (time) => {
    if (!time) return "";
    const [hours, minutes, seconds] = time.split(":");
    let hoursNum = parseInt(hours, 10);
    const amPm = hoursNum >= 12 ? "PM" : "AM";
    hoursNum = hoursNum % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${hoursNum}:${minutes} ${amPm}`;
  };

  return (
    <>
      {loading && <div className="loader">Loading...</div>}
      <div className="booking-from-sec">
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="start_date" className="form-label">
                      Start Date
                    </label>

                    <Controller
                      name="start_date"
                      control={control}
                      rules={{
                        required: "Start Date is required",
                        validate: (value) =>
                          value instanceof Date && !isNaN(value)
                            ? true
                            : "Please select a valid date",
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={field.value}
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              field.onChange(date);
                              setValue("start_date", date);
                              setValue("end_date", "");
                              setValue("start_time", "");
                              setValue("hours", "");
                              clearErrors("start_time"); // Clear start_time error when the date is changed
                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            }
                          }}
                          autoComplete="off"
                          disabledKeyboardNavigation // Ensures no keyboard interaction
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          minDate={new Date()}
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              "Backspace",
                              "/",
                              "0",
                              "1",
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                              "9",
                            ];

                            if (!allowedKeys.includes(e.key)) {
                              e.preventDefault();
                            } else {
                              console.log(e.target.value);
                            }
                          }}
                        />
                      )}
                    />

                    {errors.start_date && (
                      <div className="text-danger">
                        {errors.start_date.message}
                      </div>
                    )}
                  </div>
                </div>
                {data?.open_24_hours == 1 ? (
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="end_date" className="form-label">
                        End Date
                      </label>
                      <Controller
                        name="end_date"
                        control={control}
                        rules={{
                          required: "End Date is required",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => {
                              if (!startDate || !(startDate instanceof Date)) {
                                console.error("Invalid startDate:", startDate);
                                return;
                              }

                              const startDateOnly = new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate()
                              );
                              const endDateOnly = new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate()
                              );

                              // Calculate the difference in days
                              const days = Math.max(
                                Math.ceil(
                                  (endDateOnly - startDateOnly) /
                                    (1000 * 60 * 60 * 24) +
                                    1
                                ),
                                1
                              );

                              // Update the form state
                              setValue("end_date", date);
                              setValue("days", days);
                              setValue("calculation_status", false);
                              field.onChange(date);
                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            }}
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={startDate}
                            disabled
                          />
                        )}
                      />
                      {errors.end_date && (
                        <div className="text-danger">
                          {errors.end_date.message}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <label htmlFor="end_date" className="form-label">
                        End Date
                      </label>
                      <Controller
                        name="end_date"
                        control={control}
                        rules={{
                          required: "End Date is required",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            onChange={(date) => {
                              if (!startDate || !(startDate instanceof Date)) {
                                console.error("Invalid startDate:", startDate);
                                return;
                              }

                              const startDateOnly = new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate()
                              );
                              const endDateOnly = new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate()
                              );

                              // Calculate the difference in days
                              const days = Math.max(
                                Math.ceil(
                                  (endDateOnly - startDateOnly) /
                                    (1000 * 60 * 60 * 24) +
                                    1
                                ),
                                1
                              );

                              // Update the form state
                              setValue("end_date", date);
                              setValue("days", days);
                              setValue("calculation_status", false);
                              field.onChange(date);
                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            }}
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={startDate}
                            disabledKeyboardNavigation
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "/",
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                              ];

                              if (!allowedKeys.includes(e.key)) {
                                e.preventDefault();
                              } else {
                                console.log(e.target.value);
                              }
                            }}
                          />
                        )}
                      />
                      {errors.end_date && (
                        <div className="text-danger">
                          {errors.end_date.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="start_time" className="form-label">
                      Start Time
                    </label>
                    <Controller
                      name="start_time"
                      control={control}
                      rules={{
                        required: "Start Time is required",
                      }}
                      render={({ field }) => (
                        <TimePicker
                          format="hh:mm aa"
                          showMeridiem
                          {...field}
                          value={field.value || null} // Ensure the value is correctly passed
                          className="form-control p-0"
                          onChange={(time) => {
                            const startDate = getValues("start_date");
                            const today = new Date();

                            // Convert `startDate` to only a Date without time for comparison
                            const selectedDate = new Date(startDate);
                            const isToday =
                              selectedDate.getDate() === today.getDate() &&
                              selectedDate.getMonth() === today.getMonth() &&
                              selectedDate.getFullYear() ===
                                today.getFullYear();

                            if (isToday) {
                              const currentTime = new Date();
                              const [currentHours, currentMinutes] = [
                                currentTime.getHours(),
                                currentTime.getMinutes(),
                              ];

                              let selectedHours, selectedMinutes;

                              // Check if time is a string, then split
                              if (typeof time === "string") {
                                [selectedHours, selectedMinutes] = time
                                  .split(":")
                                  .map(Number);
                              } else if (time instanceof Date) {
                                selectedHours = time.getHours();
                                selectedMinutes = time.getMinutes();
                              }

                              // Create Date objects for comparison
                              const currentDateTime = new Date();
                              currentDateTime.setHours(
                                currentHours,
                                currentMinutes,
                                0
                              );

                              const selectedDateTime = new Date();
                              selectedDateTime.setHours(
                                selectedHours,
                                selectedMinutes,
                                0
                              );

                              if (selectedDateTime < currentDateTime) {
                                setError("start_time", {
                                  type: "manual",
                                  message:
                                    "Start time cannot be earlier than the current time.",
                                });
                                return;
                              } else {
                                clearErrors("start_time");
                              }
                            }

                            field.onChange(time); // Update the field value
                            setValue("start_time", time);
                            setValue("calculation_status", false);
                            setValue("hours", "");
                            setValue("end_time", "");
                            setIsCalculationDone(false);
                            setButtonOfPayment(false);
                          }}
                        />
                      )}
                    />
                    {errors.start_time && (
                      <div className="text-danger">
                        {errors.start_time.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="start_date" className="form-label">
                      Hours
                    </label>
                    <Controller
                      name="hours"
                      control={control}
                      rules={{
                        required: "Hours are required",
                        min: { value: 1, message: "Minimum value is 1" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          name="hours"
                          placeholder="Enter Hours"
                          {...register("hours", {
                            required: "Valid Hours Is Required",
                          })}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Ensure the input is an integer and less than or equal to 99
                            if (/^\d+$/.test(value)) {
                              // && parseInt(value) <= 99
                              let parsedValue = parseInt(value, 10);

                              // Ensure minimum value is 1
                              if (parsedValue < 1) {
                                parsedValue = 1;
                              }

                              setValue("hours", parsedValue);
                              setValue("calculation_status", false);
                              field.onChange(parsedValue);

                              const startTime = getValues("start_time"); // Get start_time value
                              const startDateValue = getValues("start_date"); // Get start_date value

                              let formattedStartTime = startTime;
                              if (startTime instanceof Date) {
                                formattedStartTime = startTime.toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                );
                              }

                              const startDate = new Date(startDateValue);

                              if (
                                formattedStartTime &&
                                !isNaN(startDate.getTime())
                              ) {
                                const [
                                  timeWithoutAmPm,
                                  period,
                                ] = formattedStartTime.split(" ");
                                let [
                                  startHour,
                                  startMinute,
                                ] = timeWithoutAmPm.split(":").map(Number);

                                // Convert start time to 24-hour format
                                if (period === "PM" && startHour !== 12) {
                                  startHour += 12;
                                } else if (
                                  period === "AM" &&
                                  startHour === 12
                                ) {
                                  startHour = 0;
                                }

                                startDate.setHours(startHour, startMinute);

                                // Add the specified hours to calculate the end date and time
                                const endDate = new Date(startDate);
                                endDate.setHours(
                                  endDate.getHours() + parsedValue
                                );

                                const formattedEndTime = endDate.toString();

                                // Handle date change logic
                                if (data?.open_24_hours == 1) {
                                  setValue("end_date", endDate);
                                }
                                setValue("end_time", formattedEndTime);
                              } else {
                                console.error(
                                  "Invalid startDate or startTime:",
                                  startDate,
                                  formattedStartTime
                                );
                              }
                            } else {
                              field.onChange("");
                            }

                            setIsCalculationDone(false);
                            setButtonOfPayment(false);
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.hours,
                          })}
                        />
                      )}
                    />
                    {errors?.hours && (
                      <span className="invalid-feedback">
                        {errors.hours.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="capacity" className="form-label">
                      Capacity
                    </label>
                    {/* <Controller
                      name="capacity"
                      control={control}
                      rules={{
                        required: "Capacity is required",
                        // min: { value: 1, message: "Give Positive Value" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          placeholder="Enter Crew Capacity"
                          // min="1"
                          value={field.value || ""} // Ensures field can be cleared
                          onChange={(e) => {
                            let value = e.target.value;

                            // Ensure value is positive
                            if (value >= 0);

                            const parsedValue = parseFloat(value);

                            field.onChange(parsedValue); // Updates the form state
                            setValue("calculation_status", false);
                            setIsCalculationDone(false);
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.capacity,
                          })}
                        />
                      )}
                    /> */}

                    <Controller
                      name="capacity"
                      control={control}
                      rules={{
                        required: "Capacity is required",
                        validate: (value) =>
                          value > 0 || "Capacity must be a positive number",
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          placeholder="Enter Crew Capacity"
                          value={field.value || ""} // Ensures field can be cleared
                          onChange={(e) => {
                            let value = e.target.value;

                            // Ensure value is a positive number
                            if (
                              value === "" ||
                              (parseFloat(value) > 0 && !isNaN(value))
                            ) {
                              const parsedValue = parseFloat(value);
                              field.onChange(parsedValue); // Updates the form state
                              setValue("calculation_status", false);
                              setIsCalculationDone(false);
                              setButtonOfPayment(false);
                            } else {
                              // Reset to previous valid value if not positive
                              e.target.value = field.value || "";
                            }
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.capacity,
                          })}
                        />
                      )}
                    />

                    {errors?.capacity && (
                      <span className="invalid-feedback">
                        {errors.capacity.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <label htmlFor="end_time" className="form-label">
                      End Time
                    </label>
                    <Controller
                      name="end_time"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...field}
                          className="form-control p-0"
                          format="hh:mm a"
                          value={field.value ? new Date(field.value) : null}
                          disabled
                        />
                      )}
                    />
                    {errors.end_time && (
                      <div className="text-danger">
                        {errors.end_time.message}
                      </div>
                    )}
                  </div>

                  {chargesError && <div style={errorStyle}>{chargesError}</div>}
                </div>
                <div className="col-lg-9 col-md-6 text-end icresvaliderrr">
                  <p>{calculationError}</p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>

          <div className="col-lg-11 mx-auto mt-5">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Whole day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      ₹ {data?.full_day_charges} ({data?.full_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Half day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      ₹ {data?.half_day_charges} ({data?.half_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Hourly Charges:</Form.Label>
                    <p className="charges-text-holder">
                      ₹ {data?.hourly_basis_charges}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Start Time</Form.Label>
                    <p className="charges-text-holder">
                      {convertToAmPm(data?.full_d_start_time)}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>End Time</Form.Label>
                    <p className="charges-text-holder">
                      {convertToAmPm(data?.full_d_end_time)}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Studio crew Capacity</Form.Label>
                    <p className="charges-text-holder">
                      {data?.studio_crew_capacity}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 mx-auto ">
            <Form>
              <h6 className="estimatedh6">
                {/* Estimated Booking Cost: ₹ {details?.total} /- */}
              </h6>

              <div className="form-bg">
                <div className="form-group">
                  <Form.Label>Enter Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    {...register("name", {
                      required: "Name Is Required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.name,
                      // "is-valid": getValues("name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors?.name && (
                    <sup className="text-danger">{errors?.name?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Email*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                      // "is-valid": getValues("email"),
                    })}
                  />
                  {errors?.email && (
                    <sup className="text-danger">{errors?.email?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Contact Number*</Form.Label>
                  <Controller
                    name="contact_no"
                    control={control}
                    defaultValue="+91"
                    rules={{
                      required: "Contact Number is required",
                      minLength: {
                        value: 13, // Including +91 and 10 digits
                        message: "Number should be at least 13 characters",
                      },
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={13} // Allow 13 characters (+91 + 10 digits)
                        value={field.value ? field.value : "+91"} // Ensure default value stays
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Project Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="name"
                    placeholder="Enter Description"
                    {...register("description", {
                      // required: "Description required",
                    })}
                  />
                </div>
              </div>

              <div className="form-bg mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Add Ons<span> (Optional)</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-opn mb-3">
                        <div className="add-one">
                          <h6 className="addh6">ADD-ONS</h6>
                          <Controller
                            name="add_on_id"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <>
                                {data?.studio_add_ons?.map((val) => (
                                  <Form.Check
                                    key={val.id}
                                    type="checkbox"
                                    label={`${val.add_on.name} + ${val.rate}`}
                                    onChange={(e) => {
                                      setValue("calculation_status", false);
                                      setChecked(e.target.checked);
                                      setIsCalculationDone(false);
                                      setButtonOfPayment(false);
                                      handleCheckboxChange(
                                        val.id,
                                        e.target.checked,
                                        val.rate
                                      );
                                    }}
                                    checked={field.value.includes(val.id)}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="acc-opn">
                        <div className="add-one">
                          <h6 className="addh6">ADDITIONAL SUPPORT</h6>
                          <Form>
                            <Controller
                              name="additional_support_id"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <>
                                  {dataSupport?.map((val) => (
                                    <Form.Check
                                      key={val.id}
                                      type="checkbox"
                                      label={`${val.name}`}
                                      onChange={(e) =>
                                        handleCheckboxChangeSupport(
                                          val.id,
                                          e.target.checked
                                        )
                                      }
                                      checked={field.value.includes(val.id)}
                                    />
                                  ))}
                                </>
                              )}
                            />
                            <div className="form-group mt-5">
                              <Form.Label className="addlabhh">
                                Add Additional Support
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                type="text"
                                name="name"
                                placeholder="Enter Description"
                                {...register("support_description", {})}
                              />
                            </div>
                            {
                              <div className="row justify-content-end">
                                <div className="col-md-6 col-12">
                                  <div className="Bifurcation_charges_text">
                                    <div className="Bifurcation_content-holder">
                                      <div className="text-holder">
                                        <h4>Days:-</h4>
                                        <span> {details?.days}</span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Full Day Charges x (
                                          {details?.charges?.full_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.full_h_p}
                                        </span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Half Day Charges x (
                                          {details?.charges?.half_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.half_h_p}
                                        </span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Hourly Basis Charges x (
                                          {details?.charges?.min_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.min_h_p}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>Add On Charges:-</h4>
                                        <span>₹ {details?.add_one_price}</span>
                                      </div>

                                      <hr></hr>

                                      <div className="text-holder">
                                        <h4>Base Total:-</h4>
                                        <span>₹ {details?.base_price}</span>
                                      </div>

                                      {/* <div className="text-holder">
                                        <h4>Base Price Discount:-</h4>
                                        <span>
                                          ₹ {details?.base_price_discount}%
                                        </span>
                                      </div> */}

                                      <div className="text-holder">
                                        <h4>
                                          Base Price Discount(
                                          {details?.base_price_discount
                                            ? details?.base_price_discount +
                                              " %"
                                            : ""}
                                          ) :-
                                        </h4>
                                        <span>
                                          - ₹ {details?.basepricediscountrupees}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>
                                          Free Hours(
                                          {details?.free_h_p_discount != 0 &&
                                            userSubscription?.subscription
                                              ?.subscription_detail
                                              ?.free_booking_hours}
                                          ):-
                                        </h4>
                                        <span>
                                          - ₹ {details?.free_h_p_discount}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>After Offer :-</h4>
                                        <span> ₹ {details?.afteroffer}</span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>
                                          {" "}
                                          PlatFrom Charges(
                                          {details?.platform_charge}%)
                                        </h4>
                                        <span>+ ₹ {details?.p_charge_p}</span>
                                      </div>

                                      <div className="text-holder">
                                        <h4> GST({details?.gst}%) :-</h4>
                                        <span>
                                          + ₹ {details?.gstchargestoshow}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>Total:-</h4>
                                        <span>₹ {details?.total}</span>
                                      </div>
                                    </div>

                                    <div className="col-lg-9 col-md-6 text-end icresvaliderrr">
                                      <p>{calculationError}</p>
                                    </div>

                                    <div className="calculate-btn-holder">
                                      {!isCalculationDone && (
                                        <button
                                          type="button"
                                          className="calculate-btn"
                                          disabled={isButtonDisabled}
                                          onClick={() => {
                                            clearErrors("calculation_status");
                                            handleSubmit(onSubmitCalculation)();
                                          }}
                                        >
                                          Check Availability
                                        </button>
                                      )}

                                      {errors.calculation_status && (
                                        <div className="text-danger">
                                          {errors.calculation_status.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </Form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              {loading ? (
                <div className="text-center mt-5">
                  <ColorRing
                    className="blocks-wrapper"
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              ) : (
                <>
                  {buttonOfPayment !== true ? (
                    " "
                  ) : userdata?.amount >= details?.total ? (
                    <div className="text-center mt-5">
                      <buttton
                        className="proceed-btn"
                        onClick={() => {
                          handleSubmit(); // Call handleSubmit first
                          setModalWalleteShow(true); // Then setModalWalleteShow to true
                        }}
                      >
                        Open Wallet Model
                      </buttton>
                    </div>
                  ) : userdata?.subscription_status == 1 ? (
                    data?.premium_studio_status == 1 &&
                    userSubscription?.premium_studio_count <= 0 ? (
                      <>
                        <div className="text-center mt-5">
                          <button
                            type="button"
                            className="proceed-btn"
                            onClick={() =>
                              alert("Access To Premium Studios has Exceeded")
                            }
                          >
                            Proceed to Pay
                          </button>
                        </div>
                      </>
                    ) : data?.premium_studio_status == 1 &&
                      userSubscription?.premium_studio_count == "All" ? (
                      <div className="text-center mt-5">
                        <buttton
                          className="proceed-btn"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Proceed to Pay
                        </buttton>
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <buttton
                          className="proceed-btn"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Proceed to Pay
                        </buttton>
                      </div>
                    )
                  ) : data?.premium_studio_status == 1 ? (
                    <div className="text-center mt-5">
                      <button
                        className="proceed-btn"
                        type="button"
                        onClick={() => alert("You cannot Book Premium Studio")}
                      >
                        Proceed to Pay
                      </button>
                    </div>
                  ) : (
                    <div className="text-center mt-5">
                      <buttton
                        type="button"
                        className="proceed-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Proceed to Pay
                      </buttton>
                    </div>
                  )}
                </>
              )}
            </Form>
          </div>
        </div>
      </div>

      <Success_modal
        maintext={"Booking Successfully"}
        smalltext={"Soon you will be notified by email & sms."}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Wallet_Payment_modal
        setPaymentType={setPaymentType}
        handleSubmit={handleSubmit(onSubmit)}
        loading={loading}
        setLoading={setLoading}
        show={modalWalletShow}
        onHide={() => setModalWalleteShow(false)}
      />
    </>
  );
};

export default Daily_form;
