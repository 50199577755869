import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useRazorpay from "react-razorpay";
import Modal from "react-bootstrap/Modal";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import Success_modal from "../Success_modal/Success_modal";
import { walletAmount } from "../../../../utils/apis/myAccount/myAccount";

const Withdraw_Recharge_modal = (props) => {
  const [modalShowSuccess, setModalShowSuccess] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(""); // Error message state
  const navigate = useNavigate();
  const [Razorpay, isLoaded] = useRazorpay();
  const { postData, signin, userdata } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  // Handle Razorpay Payment Initialization
  const handleRazorpay = useCallback(async () => {
    if (!amount || amount <= 0) {
      setError("Amount is required and must be greater than 0");
      return;
    }

    setError(""); // Clear error message if validation passes

    try {
      const options = {
        key: "rzp_test_KCP2DQFiHRB3cs",
        amount: amount * 100,
        currency: "INR",
        name: "Flick And Shoot",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        handler: async (res) => {
          await submitPayment(res.razorpay_payment_id);
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  }, [Razorpay, amount]);

  // Submit Payment to Backend
  const submitPayment = async (razorpay_id) => {
    try {
      const paydata = {
        razorpay_id,
        user_id: Cookies.get("user_id"),
        amount,
      };

      const response = await walletAmount(paydata);

      if (response?.success) {
        setModalShowSuccess(true);
        reset();
        setTimeout(() => {
          setModalShowSuccess(false);
          if (props.onHide) {
            props.onHide(true);
          }
        }, 3000);
        // navigate("/account");
      } else {
        setError(response?.message || "Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const handleAmountChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setAmount(isNaN(newValue) ? "" : newValue); // Set valid numeric value
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="withdrawmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Wallet Recharge
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form role="form">
            <div className="col-md-12">
              <div className="main-form-section mt-3">
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      className={classNames("", {
                        "is-invalid": errors?.amount || error,
                      })}
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const numericValue = Number(value);
                          if (!Number.isInteger(numericValue)) {
                            return "Amount must be an integer.";
                          }
                          if (numericValue <= 0) {
                            return "Amount must be greater than 0.";
                          }

                          return true;
                        },
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value.includes(".")) handleAmountChange(e);
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </InputGroup>
                  {errors.amount && (
                    <span className="text-danger">{errors.amount.message}</span>
                  )}
                  {error && <span className="text-danger">{error}</span>}
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button className="close-btn" onClick={props.onHide}>
            Close
          </Button>
          <Button className="save-btn" onClick={handleRazorpay}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Success_modal
        show={modalShowSuccess}
        smalltext="Wallet Recharge Successful"
        onHide={() => setModalShowSuccess(false)}
      />
    </>
  );
};

export default Withdraw_Recharge_modal;
