import { useEffect, useState } from "react";
import "./loginform.css";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faHouse } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";
import { useForm } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import { ChangePassword } from "../../utils/apis/common/Common";

const Setnew_password = () => {
  const [modalShow, setModalShow] = useState(false);

  const { email_or_contact } = useParams();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const onSubmit = async (data) => {
    const res = await ChangePassword(email_or_contact, data.password);
    if (res.success) {
      setModalShow(true);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setError("re_password", {
        message: res.message,
      });
    }
  };

  return (
    <>
      <section className="login-page-main-sec">
        <div className="container main_form_container_holder">
          <div className="main-position-div">
            <div className="d-flex aligndiv">
              <div className="mid-img-div">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/login/mid-img2.png"
                  }
                />
              </div>

              <div className="last-div slidein margin-top">
                <div className="loginformsec topspace">
                  <Link to="/forgot-password">
                    <FontAwesomeIcon
                      className="home-icn"
                      icon={faChevronLeft}
                    />
                  </Link>
                  <h1 className="login-title">Set New Password</h1>
                  <p className="loginby">
                    Your new password must be different to previously used
                    password.
                  </p>

                  <div className="form-div ">
                    <Form>
                      <div className="scrollable_form_holder">
                        <Form.Group className="form-group">
                          <Form.Label>Enter Password</Form.Label>

                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password*"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="eye-icc">
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="eye-icc"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            />
                          </div>
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type={showPasswordOne ? "text" : "password"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div className="eye-icc">
                            <FontAwesomeIcon
                              icon={iconTwo}
                              className="eye-icc"
                              onClick={() =>
                                togglePasswordVisibility("reenterPassword")
                              }
                            />
                          </div>
                          {errors?.re_password && (
                            <sup className="text-danger">
                              {errors?.re_password?.message}
                            </sup>
                          )}
                          {password && (
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                              ]}
                              minLength={8}
                              value={password}
                              valueAgain={passwordAgain}
                              onChange={(isValid) => {}}
                              style={{
                                fontSize: "10px",
                                padding: "4px",
                                margin: "0",
                              }}
                            />
                          )}
                        </Form.Group>
                      </div>

                      <Link to="#">
                        <button
                          type="button"
                          // onClick={() => setModalShow(true)}
                          onClick={handleSubmit(onSubmit)}
                          className="Login-btn resetpasstop"
                        >
                          Submit
                        </button>
                      </Link>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Success_modal
        maintext={"Password Reset Successfully"}
        smalltext={""}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Setnew_password;
