import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { FAQdata } from "../../utils/apis/master/Master";
import parse from "html-react-parser";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [data, setData] = useState();


  const getAllData = async () => {
    const res = await FAQdata();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="faq">
        <section className="About_banner_sec">
          <div className="container-fluid p-0">
            <img
              className="banner-img"
              src={
                process.env.PUBLIC_URL + "/assets/images/subscription/FAQs.png"
              }
              alt="FAQs banner"
            />
            <div className="overlayblack"></div>
            <div className="overlay-text">
              <h3 className="banner-title">Queries on mind?</h3>
              <h6 className="subtitle">We will gladly help you</h6>
            </div>

            <div className="home-return-pos">
              <div className="container">
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>
        </section>

        <Container>
          <div className="background-card">
            <img
              className="curve-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/about-us/banner/layer.png"
              }
              alt="Curve"
            />
            {data?.map((faq, index) => (
              <Accordion defaultActiveKey={data[0]?.id} key={index}>
                <Accordion.Item eventKey={faq?.id}>
                  <Accordion.Header>{faq?.name}</Accordion.Header>
                  <Accordion.Body>{parse(faq?.description)}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Faq;
