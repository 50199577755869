import React, { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import { AboutBanner } from "../../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const About_banner = () => {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await AboutBanner();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="About_banner_sec">
        <div className="container-fluid p-0">
    
          <img className="banner-img" src={IMG_URL + data?.image} />
          <div className="overlayblack"></div>
          <div className="overlay-text">
            <h3 className="banner-title">Pleasure to Meet You</h3>
            <h6 className="subtitle">{data?.name}</h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default About_banner;
