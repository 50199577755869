import React, { useEffect, useRef, useState, useContext } from "react";
import "./MediaCardSec.css";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSliders,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import StudioMediaCard from "./StudioMediaCard/StudioMediaCard";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import FilterModal from "./FilterModal/FilterModal";
import { Context } from "../../../utils/context";
import { mediaSpace } from "../../../utils/apis/MediaSpace/MediaSpace";
const MediaCardSec = ({
  data,
  id,
  location,
  setLocation,
  price,
  setPrice,
  rating,
  setRating,
  settotalPages,
  totalPages,
  setonPageChange,
  onPageChange,
  liveLocation,
  pincodes,
  longitude,
  latitude,
}) => {
  const [modalShow, setModalShow] = useState(false);

  const [dataLink, setDataLink] = useState();
  const [currentPage, setcurrentPage] = useState();
  // const [totalPages, settotalPages] = useState();

  const { IMG_URL, userdata } = useContext(Context);

  const handleNavLinkClick = async () => {
    const res = await mediaSpace(onPageChange, id, true);
    if (res?.success) {
      setDataLink(res?.data?.data);
      settotalPages(res?.data?.totalPages);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  useEffect(() => {
    handleNavLinkClick();
  }, [onPageChange]);

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  return (
    <>
      <section className="media-card-sec pb-5">
        <div className="container-lg mt-5">
          <div className="background-card med-top">
            <img
              className="curve-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/about-us/banner/layer.png"
              }
            />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <div className="d-flex spacing">
                  <div className="ms-auto">
                    <Nav
                      variant="pills"
                      className="justify-content-center media-card-sec"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first" data-hover="All">
                          <span>All</span>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          data-hover="Premium"
                          onClick={handleNavLinkClick}
                        >
                          <span>Premium</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="ms-auto">
                    <div
                      className="slider-icon "
                      onClick={() => setModalShow(true)}
                    >
                      <FontAwesomeIcon icon={faSliders} className="slide-fa" />
                    </div>
                  </div>
                </div>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Row>
                      {data?.data && data.data.length > 0 ? (
                        data.data.map((item, index) => (
                          <Col key={index} xxl={3} xl={3} lg={4} md={4} sm={6}>
                            {item?.id ? (
                              <Link to={`/studio-inner/${item.id}`}>
                                <StudioMediaCard
                                  image={
                                    item?.studio_images &&
                                    item.studio_images.length > 0
                                      ? IMG_URL + item.studio_images[0]?.images
                                      : "default_image_url"
                                  }
                                  LocationText={item?.address}
                                  Title={item?.name}
                                  averageRating={
                                    item?.averageRating
                                      ? item?.averageRating
                                      : 0
                                  }
                                  numberRate={
                                    item?.averageRating > 0
                                      ? item?.averageRating
                                      : 0
                                  }
                                  isPreBooked={item?.prebooked}
                                  isBooked={item?.booked}
                                />
                              </Link>
                            ) : (
                              <div>{alert("Studio is inactive")}</div>
                            )}
                          </Col>
                        ))
                      ) : (
                        <div>
                          <h3 className="no-data-text-holder">
                            No Studio Available
                          </h3>
                        </div>
                      )}

                      <Col lg={12} className="mx-auto">
                        <div className="mt-5">
                          <Pagination className="justify-content-center">
                            <Pagination.Prev
                              disabled={onPageChange === 1}
                              onClick={() => handlePageChange(onPageChange - 1)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="page-link-prev"
                              />
                            </Pagination.Prev>

                            {paginationItems}

                            <Pagination.Next
                              disabled={onPageChange === totalPages}
                              onClick={() => handlePageChange(onPageChange + 1)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="page-link-next"
                              />
                            </Pagination.Next>
                          </Pagination>
                          {/* <Pagination className="justify-content-center">
                            <Pagination.Prev>
                              <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="page-link-prev"
                              />
                            </Pagination.Prev>
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Next>
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="page-link-next"
                              />
                            </Pagination.Next>
                          </Pagination> */}
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey="second">
                    <Row>
                      {dataLink?.length > 0 ? (
                        dataLink.map((item, index) => (
                          <Col key={index} xxl={3} xl={3} lg={4} md={4} sm={6}>
                            <Link to={`/studio-inner/${item?.id}`}>
                              <StudioMediaCard
                                image={
                                  item?.studio_images &&
                                  item.studio_images.length > 0
                                    ? IMG_URL + item.studio_images[0]?.images
                                    : "default_image_url"
                                }
                                LocationText={item?.address}
                                Title={item?.name}
                                averageRating={
                                  item?.averageRating ? item?.averageRating : 0
                                }
                                numberRate={
                                  item?.averageRating > 0
                                    ? item?.averageRating
                                    : 0
                                }
                                isPreBooked={item?.prebooked}
                                isBooked={item?.booked}
                              />
                            </Link>
                          </Col>
                        ))
                      ) : (
                        <div>
                          <h3 className="no-data-text-holder">
                            No Studio Available
                          </h3>
                        </div>
                      )}

                      <Col lg={12} className="mx-auto">
                        <div className="mt-5">
                          <Pagination className="justify-content-center">
                            <Pagination.Prev
                              disabled={onPageChange === 1}
                              onClick={() => handlePageChange(onPageChange - 1)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="page-link-prev"
                              />
                            </Pagination.Prev>

                            {paginationItems}

                            <Pagination.Next
                              disabled={onPageChange === totalPages}
                              onClick={() => handlePageChange(onPageChange + 1)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="page-link-next"
                              />
                            </Pagination.Next>
                          </Pagination>
                          {/* <Pagination className="justify-content-center">
                            <Pagination.Prev>
                              <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="page-link-prev"
                              />
                            </Pagination.Prev>
                            <Pagination.Item className="active">
                              {1}
                            </Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Item>{4}</Pagination.Item>
                            <Pagination.Next>
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="page-link-next"
                              />
                            </Pagination.Next>
                          </Pagination> */}
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
      <FilterModal
        show={modalShow}
        modalShow={modalShow}
        setModalShow={setModalShow}
        location={location}
        setLocation={setLocation}
        price={price}
        setPrice={setPrice}
        rating={rating}
        setRating={setRating}
        liveLocation={liveLocation}
        pincodes={pincodes}
        longitude={longitude}
        latitude={latitude}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default MediaCardSec;