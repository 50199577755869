import React, { useContext } from "react";
import { Context } from "../../../utils/context";

const MediaBanner = ({ data }) => {

  const { IMG_URL } = useContext(Context);

  return (
    <section className="About_banner_sec">
      <div className="container-fluid p-0">
        {data ? (
          <>
            <img
              className="banner-img"
              src={IMG_URL + data?.large_image}
              alt={data?.name}
            />
            <div className="overlay-text">
              <h3 className="banner-title">{data?.name}</h3>
              <h6 className="subtitle"></h6>
            </div>
          </>
        ) : (
          <div className="placeholder-message">
            <p>Please upload data</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default MediaBanner;
